import React, { useState } from 'react'
import { FormField, TextInputField, Avatar, Heading, Button, PlusIcon, Tooltip, toaster, CrossIcon, SelectMenu, Pane, Spinner } from 'evergreen-ui'
import { ChromePicker } from 'react-color'
import AddInfluencerPop from './AddInfluencerPop'
import { useDispatch, useSelector } from 'react-redux'
import {useHistory} from 'react-router-dom'
import { newProject } from '../store/actions/projectsAction'


function CreateProjectForm() {
    const [topLeftColor, setTopLeftColor] = useState({ hex: "#F8006C" })
    const [bottomRightColor, setBottomRightColor] = useState({ hex: "#9A00F8" })

    const [processing, setProcessing] = useState(false)
    const [isDialogShow, setIsDialogShow] = useState(false)

    const [influencers, setInfluencers] = useState([])
    const [projectName, setProjectName] = useState("")
    const [selectedCompany, setSelectedCompany] = useState("")

    const history = useHistory()

    const dispatch = useDispatch()
    const createProjectAction = (project) => dispatch(newProject(project))
    const companiesSelector = useSelector(state => state.companies)

    const getCompanyID = (companyName) => {

        const company = companiesSelector.companies.find(company => company.value === companyName)
        return company.id
    }

    const getInfluencersIDs = (influencers) => {
        const ids = influencers.map(influencer => influencer.id)
        return ids
    }

    const getGradient = (color1, color2) => {
        return `linear-gradient(180deg, ${color1.hex} 0%, ${color2.hex} 100%)`
    }

    const openDialog = () => setIsDialogShow(true)
    const closeDialog = () => setIsDialogShow(false)

    const onInfluencerAdd = (influencer) => {
        let newInfluencers = influencers

        if (influencers.find(item => item.id === influencer.id)) {
            toaster.danger("Инфлюенсер уже добавлен")
            return
        }

        newInfluencers.push(influencer)
        setInfluencers(newInfluencers)
    }

    const removeInfluencer = (influencerId) => {
        let newInfluencers = influencers

        newInfluencers = newInfluencers.filter(influencer => influencer.id !== influencerId)
        setInfluencers(newInfluencers)
    }

    const createProject = async () => {

        if (projectName.trim().length === 0) {
            toaster.danger("Название проекта не заполнено")
            return
        }

        if (influencers.length === 0) {
            toaster.danger("Список инфлюенсеров пуст")
            return
        }

        if (selectedCompany.length === 0) {
            toaster.danger("Компания не выбрана")
            return
        }

        let projectData = {
            name: projectName,
            status: 1,
            company: selectedCompany,
            companyID: getCompanyID(selectedCompany),
            colors: [topLeftColor, bottomRightColor],
            influencers: influencers,
            influencersIDs: getInfluencersIDs(influencers)
        }
        setProcessing(true)
        await createProjectAction(projectData)
        setProcessing(false)
        history.push('/admin/projects')

}

if (processing) return (
    <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
        <Spinner />
    </Pane>

)
return (
    <React.Fragment>
        <AddInfluencerPop
            isShown={isDialogShow}
            closeDialog={() => closeDialog()}
            onInfluencerAdd={(influencer) => onInfluencerAdd(influencer)}
        />
        <div
            style={{
                border: "3px solid #E4E7EB",
                padding: "30px",
                marginTop: "25px"
            }}
        >
            <TextInputField
                required
                // value={}
                // onChange={e => props.setName(e.target.value)}
                label="Название проекта"
                placeholder="Новый год..."
                value={projectName}
                onChange={e => setProjectName(e.target.value)}
            />
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                <FormField
                    marginBottom={24}
                    label="Цвет верхнего левого угла"
                    isRequired
                >
                    <ChromePicker color={topLeftColor} onChange={setTopLeftColor} />
                </FormField>
                <div
                    style={{
                        width: "200px",
                        height: "250px",
                        borderRadius: "30px",
                        background: getGradient(topLeftColor, bottomRightColor),
                        margin: "0 25px"
                    }}
                >

                </div>
                <FormField
                    marginBottom={24}
                    label="Цвет нижнего правого угла"
                    isRequired
                >
                    <ChromePicker color={bottomRightColor} onChange={setBottomRightColor} />
                </FormField>
            </div>

            <div style={{
                marginTop: "30px",
                display: "flex",
                justifyContent: "start"
            }}>
                <Heading size={700} marginRight={10}>Компания</Heading>
                <SelectMenu
                    title="Выбор компании"
                    filterPlaceholder={"Поиск компании"}
                    options={companiesSelector.companies}
                    selected={selectedCompany}
                    onSelect={item => setSelectedCompany(item.value)}
                >
                    <Button intent="success" iconBefore={PlusIcon}>{selectedCompany || 'Выбрать'}</Button>
                </SelectMenu>
            </div>

            <div
                style={{
                    marginTop: "30px",
                    display: "flex",
                    justifyContent: "start"
                }}
            >
                <Heading size={700} marginRight={10}>Инфлюенсеры</Heading>
                <Button intent="success" iconBefore={PlusIcon} onClick={() => openDialog()}>Добавить</Button>
            </div>

            <div
                style={{
                    display: "flex",
                    padding: "20px 0",
                    borderRadius: "100%",
                    flexWrap: "wrap"
                }}

            >
                {influencers.map(influencer => (
                    <div style={{ position: "relative" }}>
                        <Tooltip content={influencer.name}>
                            <Avatar
                                key={influencer.id}
                                name={influencer.name}
                                src={influencer.downloadURL}
                                size={65}
                                margin={5}

                            />
                        </Tooltip>
                        <CrossIcon
                            color="danger"
                            size={16}
                            position="absolute"
                            top={-4}
                            right={-2}
                            style={{ cursor: "pointer" }}
                            onClick={() => { removeInfluencer(influencer.id) }}
                        />
                    </div>


                ))}
            </div>
            <Button
                marginTop={50}
                height={48}
                appearance="primary"
                intent="success"
                onClick={() => { createProject() }}
            >
                Создать
                    </Button>
        </div>
    </React.Fragment>

)
}

export default CreateProjectForm
