import React, { useEffect } from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Pane, Spinner } from 'evergreen-ui'


import { PrivateRoute } from '../components/PrivateRoute'
import LoginPage from '../pages/LoginPage'
import InfluencersListPage from '../pages/InfluencersListPage'
import CreateNewInfluencer from '../pages/CreateNewInfluencer'
import ProjectsListPage from '../pages/ProjectsListPage'
import CreateNewProject from '../pages/CreateNewProject'
import SingleProject from '../pages/SingleProject'
import EditInfluencerPage from '../pages/EditInfluencerPage'
import SettingsPage from '../pages/SettingsPage'
import CompanyPage from '../pages/CompanyPage'
import InfluencerStatisticPage from '../pages/InfluencerStatisticPage'
import CompaniesPage from '../pages/CompaniesPage'

import { checkLogin } from '../store/actions/auth'
import { getInfluencersList } from '../store/actions/influencersActions'
import { getProjectsList } from '../store/actions/projectsAction'
import { getTags } from '../store/actions/tagsActions'
import { getPlatforms } from '../store/actions/platformsActions'
import { getCompanies } from '../store/actions/companiesActions'

import Menu from '../components/Menu'

function AdminLayout() {

    const authSelector = useSelector(state => state.auth)

    const dispatch = useDispatch()
    const location = useLocation()
    const checkLoginAction = () => dispatch(checkLogin())
    const getInfluencersListAction = () => dispatch(getInfluencersList())
    const getProjectsListAction = () => dispatch(getProjectsList())
    const getTagsAction = () => dispatch(getTags())
    const getPlatformsAction = () => dispatch(getPlatforms())
    const getCompaniesAction = () => dispatch(getCompanies())

    useEffect(() => {
        checkLoginAction()
        getInfluencersListAction()
        getProjectsListAction()
        getTagsAction()
        getPlatformsAction()
        getCompaniesAction()
    }, []);


    if (authSelector.isLoading) return (
        <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
            <Spinner />
        </Pane>

    )
    return (
        <div>

            <div className="container" style={{ position: "relative" }}>
                {location.pathname === '/admin/' || location.pathname === '/admin'
                    ? !authSelector.isLoading && authSelector.user
                        ? <Redirect to="/admin/influencers" />
                        : <Redirect to="/admin/login" />
                    : null
                }
                {Object.keys(authSelector.user).length !== 0 ? <Menu /> : null}


                <Switch>
                    <Route exact path='/admin/login' component={LoginPage} />
                    <PrivateRoute exact path='/admin/influencers' component={InfluencersListPage} />
                    <PrivateRoute exact path='/admin/projects' component={ProjectsListPage} />
                    <PrivateRoute exact path='/admin/settings' component={SettingsPage} />
                    <PrivateRoute exact path='/admin/projects/new' component={CreateNewProject} />
                    <PrivateRoute exact path='/admin/projects/:projectID' component={SingleProject} />
                    <PrivateRoute exact path='/admin/influencers/new' component={CreateNewInfluencer} />
                    <PrivateRoute exact path='/admin/influencers/edit/:influencerID' component={EditInfluencerPage} />
                    <PrivateRoute exact path='/admin/influencers/stat/:influencerID' component={InfluencerStatisticPage} />
                    <PrivateRoute exact path='/admin/companies/' component={CompaniesPage} />
                    <PrivateRoute exact path='/admin/companies/:companyID' component={CompanyPage} />
                    <PrivateRoute exact path='/admin/companies/:companyID/:influencerID' component={CompanyPage} />

                </Switch>


            </div>
        </div>



    )
}

export default AdminLayout