import React, { useState } from 'react'
import { Heading, toaster, Pane, Spinner } from 'evergreen-ui'
import { useSelector, useDispatch } from 'react-redux'
import CreateInfluencerForm from '../components/CreateInfluencerForm'
import PreviewInfluencer from '../components/PreviewInfluencer'
import { newInfluencer } from '../store/actions/influencersActions'
import { useHistory } from 'react-router-dom'


function CreateNewInfluencer() {

    const [processing, setProcessing] = useState(false)
    const [name, setName] = useState("")
    const [subscribers, setSubscribers] = useState("")
    const [avatar, setAvatar] = useState("")
    const [selectedTags, setSelectedTags] = useState([])
    const [selectedPlatforms, setSelectedPlatforms] = useState([])
    const [comment, setComment] = useState('')

    const tagsSelector = useSelector(state => state.tags)
    const platformsSelector = useSelector(state => state.platforms)

    const dispatch = useDispatch()
    const createInfluencerAction = influencer => dispatch(newInfluencer(influencer))

    const history = useHistory()

    const addInfluencer = async (e) => {
        e.preventDefault()

        let hasError = false

        if (selectedPlatforms.length === 0) {
            toaster.danger("Выберите хотя бы одну платформу")
            hasError = true
        }

        if (selectedTags.length === 0) {
            toaster.danger("Выберите хотя бы один тэг")
            hasError = true
        }

        if (subscribers.length === 0) {
            toaster.danger("Подписчики не введены")
            hasError = true
        }

        if (name.length === 0) {
            toaster.danger("Имя не введено")
            hasError = true
        }

        if (avatar.length === 0) {
            toaster.danger("Не выбран аватар")
            hasError = true
        }

        if (hasError) return

        //Полными объектами
        // let platformObj = platformsSelector.platforms.find(platform => platform.value === selectedPlatform)
        // let tagsObjArr = tagsSelector.tags.filter(tag => !!~selectedTags.indexOf(tag.value))

        let influencerData = {
            name,
            subscribers,
            avatar: avatar,
            platform: selectedPlatforms,
            tags: selectedTags,
            comment: comment || ''
        }

        setProcessing(true)
        await createInfluencerAction(influencerData)
        toaster.success(`Инфлюенсер ${name} успешно добавлен`)
        history.push("/admin/influencers")

    }

    const setPlatformLink = (item) => {
        const newSelectedPlatforms = selectedPlatforms.map(platform => (
            platform.value === item.value
                ? { ...platform, link: item.link }
                : platform
        ))
        setSelectedPlatforms(newSelectedPlatforms)
    }

    if (processing) return (
        <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
            <Spinner />
        </Pane>
    )

    return (
        <React.Fragment>
            <div style={{
                marginBottom: "10px",
                marginTop: "50px"
            }}>
                <Heading size={900} marginBottom={8}>Добавить инфлюенсера</Heading>
            </div>

            <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
            }}>
                <CreateInfluencerForm
                    name={name}
                    subscribers={subscribers}
                    avatar={avatar}
                    selectedTags={selectedTags}
                    selectedPlatforms={selectedPlatforms}
                    comment={comment}

                    tagsSelector={tagsSelector}
                    platformsSelector={platformsSelector}

                    setName={item => setName(item)}
                    setSubscribers={item => setSubscribers(item)}
                    setAvatar={item => setAvatar(item)}
                    setSelectedTags={item => setSelectedTags(item)}
                    setSelectedPlatforms={item => setSelectedPlatforms(item)}
                    setComment={comment => setComment(comment)}
                    setPlatformLink={item => setPlatformLink(item)}

                    addInfluencer={e => addInfluencer(e)}


                />

                <PreviewInfluencer
                    colors={[{ hex: "#F8006C" }, { hex: "#9A00F8" }]}
                    name={name}
                    subscribers={subscribers}
                    platform={selectedPlatforms}
                    category={selectedTags}
                    downloadURL={avatar ? URL.createObjectURL(avatar) : null}
                />


            </div>


        </React.Fragment>
    )
}

export default CreateNewInfluencer