import React from 'react'

import AdminLayout from './layouts/AdminLayout'
import PublicLayout from './layouts/PublicLayout/PublicLayout'
import RootPage from './pages/RootPage'


import { Switch, Route } from 'react-router-dom'

function App() {



  return (
    <Switch>
      <Route exact path='/' component={RootPage} />
      <Route path='/admin' component={AdminLayout} />
      <Route path='/poll/:projectID' component={PublicLayout} />
    </Switch>
  );
}

export default App;
