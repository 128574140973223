import { ADD_NEW_INFLUENCER, RECEIVED_INFLUENCERS_LIST, EDIT_INFLUENCER } from "../actions/actionTypes"

const initialState = {
    influencers: [],
    isLoading: true,
    hasError: false
}

export default function influencersReducer(state = initialState, action) {
    switch (action.type) {
        case RECEIVED_INFLUENCERS_LIST:
            return { influencers: action.payload }
        case ADD_NEW_INFLUENCER:
            {
                let infList = state.influencers
                infList.push(action.payload)
                return { ...state, influencers: infList }
            }
        case EDIT_INFLUENCER:
            return { ...state, influencers: [...state.influencers.filter(influencer => influencer.id !== action.payload.id), action.payload] }
        default:
            return state
    }
}