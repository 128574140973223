import firebase from '../../firebase/config'
import { RECEIVED_POOL } from './actionTypes'


export function getPool(poolId) {
    return async dispatch => {
        const firebaseAnswer = await firebase.getPool(poolId)

        if (firebaseAnswer) dispatch({
            type: RECEIVED_POOL,
            payload: firebaseAnswer
        })
    }
}

export function savePool(poll) {
    return async dispatch => {
        const firebaseAnswer = await firebase.savePool(poll) 
        if (firebaseAnswer) dispatch({
            type: 'NEW_POLL',
            payload: firebaseAnswer
        })
    }
       
}