import firebase from '../../firebase/config'

import { RECEIVED_TAGS, ADD_TAG, DELETED_TAG } from './actionTypes'

export function getTags() {
    return async dispatch => {
        const firebaseAnswer = await firebase.getTags()

        if (firebaseAnswer) dispatch({
            type: RECEIVED_TAGS,
            payload: firebaseAnswer
        })
    }
}

export function addTag(tag){
    return async dispatch => {
        const firebaseAnswer = await firebase.addTag(tag)

        if(firebaseAnswer) dispatch({
            type: ADD_TAG,
            payload: firebaseAnswer
        })
    }
}

export function tagDelete(tag){
    return async dispatch => {
        const firebaseAnswer = await firebase.deleteTag(tag)

        if(firebaseAnswer) dispatch({
            type: DELETED_TAG,
            payload: tag
        })
    }
}