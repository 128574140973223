import React, { useEffect, useState } from 'react'
import { Heading, Pane, Spinner, Table } from 'evergreen-ui'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import firebase from '../firebase/config'
import { filter } from 'fuzzaldrin-plus'
import { ExportCSV } from '../components/ExcelExportButton'


function formatDate(date) {

    var dd = date.getDate();
    if (dd < 10) dd = '0' + dd;

    var mm = date.getMonth() + 1;
    if (mm < 10) mm = '0' + mm;

    var yy = date.getFullYear() % 100;
    if (yy < 10) yy = '0' + yy;

    return dd + '-' + mm + '-' + yy;
}

function getWorldFromScore(score) {
    switch (score) {
        case 1:
            return "Лайк"

        case 0:
            return "Не знаю"

        case -1:
            return "Не нравится"

        default:
            return "Ошибка"
    }
}

function InfluencerStatisticPage(props) {

    const [searchQuery, setSearchQuery] = useState("")

    const [isLoading, setIsLoadin] = useState(true)
    const [influencerPulls, setInfluencerPulls] = useState([])
    const influencersSelector = useSelector(state => state.influencers)
    const companiesSelector = useSelector(state => state.companies)

    const history = useHistory()

    const getCompanyById = (companyID) => {
        return companiesSelector.companies.find(company => company.id === companyID)
    }

    const prepareDataToExport = (polls) => {
        const returnData = []
        polls.map(poll => {
            const returnPoll = {
                'Имя': poll.name,
                'Фамилия': poll.surname,
                'Должность': poll.position,
                'Компания': getCompanyById(poll.companyID).label
            }
            poll.votes.map(vote => {
                if (vote.id === props.match.params.influencerID) {
                    returnPoll[vote.name] = getWorldFromScore(vote.score)
                }

            })
            returnData.push(returnPoll)

        })
        return returnData
    }

 

    const getInfluencerPolls = async (influencerID) => {
        await firebase.getPollsForInfluencer(influencerID).then(polls => {
            setIsLoadin(false)
            setInfluencerPulls(polls)
        })
    }

    useEffect(() => {
        getInfluencerPolls(props.match.params.influencerID)
    }, [])

    const getUniqueCompanies = (polls) => {
        const uniqueCompaniesIDs = [...new Set(polls.map(poll => {
            return poll.companyID
        }))]
        let companiesWithState = {}
        uniqueCompaniesIDs.map(id => companiesWithState[id] = { good: 0, bad: 0, neutral: 0 })

        polls.map(poll => poll.votes.map(vote => {
            if (props.match.params.influencerID === vote.id) {
                switch (vote.score) {
                    case 0:
                        companiesWithState[poll.companyID].neutral = companiesWithState[poll.companyID].neutral + 1
                        break
                    case 1:
                        companiesWithState[poll.companyID].good = companiesWithState[poll.companyID].good + 1
                        break
                    case -1:
                        companiesWithState[poll.companyID].bad = companiesWithState[poll.companyID].bad + 1
                        break
                    default:
                        break
                }
            }
        }))
        let returnArray = []
        Object.keys(companiesWithState).map(companyID => {
            const findedProject = companiesSelector.companies.find(company => company.id === companyID)
            returnArray.push({ ...findedProject, ...companiesWithState[companyID] })
        })

        return returnArray
    }

    const filterByName = companies => {
        if (searchQuery.length === 0) return companies

        return companies.filter(company => {
            const result = filter([company.label], searchQuery)
            return result.length === 1
        })
    }

    const getInfluencerById = (influencerID) => {
        return influencersSelector.influencers.find(influencer => influencer.id === influencerID)
    }

    const renderTableRow = (company) => (

        <Table.Row key={company.id} isSelectable onSelect={() => history.push(`/admin/companies/${company.id}/${props.match.params.influencerID}`)}>
            <Table.TextCell>{company.label}</Table.TextCell>
            <Table.TextCell>{company.good}</Table.TextCell>
            <Table.TextCell>{company.neutral}</Table.TextCell>
            <Table.TextCell>{company.bad}</Table.TextCell>
            {console.log(company)}
        </Table.Row>
    )

    if (isLoading) return (
        <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
            <Spinner />
        </Pane>

    )
    const uniqueCompanies = getUniqueCompanies(influencerPulls)
    const filtredCompanies = filterByName(uniqueCompanies)

    return (
        <React.Fragment>
            <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
            }}>
                <Heading size={900}>{getInfluencerById(props.match.params.influencerID).name}</Heading>
                {influencerPulls.length !== 0
                    ? <ExportCSV csvData={prepareDataToExport(influencerPulls)} fileName={`${getInfluencerById(props.match.params.influencerID).name}-${formatDate(new Date())}`} />
                    : null
                }

            </div>

            <Heading size={600} marginTop={30}>Компании</Heading>
            {influencerPulls.length !== 0
                ? <Table border marginTop={10}>
                    <Table.Head>
                        <Table.SearchHeaderCell
                            onChange={value => setSearchQuery(value)}
                            placeholder='Искать по названию...'
                        />
                        <Table.TextCell>Лайк</Table.TextCell>
                        <Table.TextCell>Не знаю</Table.TextCell>
                        <Table.TextCell>Не лайк</Table.TextCell>
                    </Table.Head>
                    <Table.Body>
                        {filtredCompanies.map(company => (
                            renderTableRow(company)
                        ))}
                    </Table.Body>
                </Table>
                : <Heading>Компании не найдены</Heading>
            }
        </React.Fragment>
    )
}

export default InfluencerStatisticPage