import React from 'react'
import { FormField, FilePicker, TextInputField, SelectMenu, Button, Textarea, Label } from 'evergreen-ui'


function isNumber(num) {
    return typeof num === 'number' && !isNaN(num);
}

function CreateInfluencerForm(props) {
    return (
        <div style={{
            width: "45%",
            padding: "35px 30px",
            border: "3px solid #E4E7EB",
            borderRadius: "5px",
            marginTop: "25px"
        }}>
            <FormField
                marginBottom={24}
                label="Аватар"
                isRequired
            >
                <FilePicker
                    required
                    accept={['image/*']}
                    onChange={files => props.setAvatar(files[0])}
                    placeholder="Выберите аватар"
                />
            </FormField>
            <TextInputField
                required
                value={props.name}
                onChange={e => props.setName(e.target.value)}
                label="Имя Фамилия"
                placeholder="Иванов Иван..."
            />
            <TextInputField
                required
                value={props.subscribers}
                onChange={e => {
                    isNumber(+e.target.value)
                        ? props.setSubscribers(+e.target.value)
                        : e.preventDefault()
                }
                }
                label="Подписчики"
                placeholder="1000...."
            />
            <Label
                marginBottom={4}
                display="block"
            >
                Комментарий
            </Label>
            <Textarea
                value={props.comment}
                onChange={e => {
                    props.setComment(e.target.value)
                }}
                label="Комментарий"
                placeholder="...."
                marginBottom='20px'
            />

            {
                props.selectedPlatforms.map((platform, index) => (
                    <TextInputField
                        key={index}
                        label={platform.value}
                        description="Введите ссылку на профиль."
                        placeholder="Ссылка на профиль"
                        value={platform.link}
                        onChange={e => props.setPlatformLink({ value: platform.value, link: e.target.value })}
                    />
                ))
            }

            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between"
                }}
            >
                <div>
                    <SelectMenu
                        isMultiSelect
                        title="Выбор тэгов"
                        filterPlaceholder={"Введите тэг..."}
                        options={props.tagsSelector.tags}
                        selected={props.selectedTags}
                        height={305}
                        onSelect={item => {
                            const selectedItems = [...props.selectedTags, item.value]
                            props.setSelectedTags(selectedItems)
                        }}
                        onDeselect={item => {
                            const deselectedItemIndex = props.selectedTags.indexOf(item.value)
                            const selectedItems = props.selectedTags.filter(
                                (_item, i) => i !== deselectedItemIndex
                            )
                            props.setSelectedTags(selectedItems)
                        }}
                    >
                        <Button marginRight={10}>
                            Выбрать Тэги
                        </Button>
                    </SelectMenu>
                    <SelectMenu
                        isMultiSelect
                        title="Выбор платформ"
                        filterPlaceholder={"Введите платформу..."}
                        options={props.platformsSelector.platforms}
                        selected={props.selectedPlatforms.map(item => item.value)}
                        height={305}
                        onSelect={item => {
                            const newPlatform = {
                                value: item.value,
                                link: ''
                            }
                            const selectedItems = [...props.selectedPlatforms, newPlatform]
                            props.setSelectedPlatforms(selectedItems)
                        }}
                        onDeselect={item => {
                            const selectedItems = props.selectedPlatforms.filter(
                                (_item) => _item.value !== item.value
                            )
                            props.setSelectedPlatforms(selectedItems)
                        }}
                    >
                        <Button>
                            Выбрать платформу
                        </Button>
                    </SelectMenu>
                </div>
                <Button onClick={e => props.addInfluencer(e)} appearance="primary" intent="success">Сохранить</Button>
            </div>


        </div>
    )
}

export default CreateInfluencerForm