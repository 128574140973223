import { RECEIVED_POOL } from '../actions/actionTypes'

const initialState = {}

export default function publicReducer(state = initialState, action) {
    switch (action.type) {
        case RECEIVED_POOL:
            return action.payload
        default:
            return state
    }
}