import firebase from '../../firebase/config'


import { RECEIVED_INFLUENCERS_LIST, ADD_NEW_INFLUENCER, EDIT_INFLUENCER} from './actionTypes'

export function getInfluencersList(){
    return async dispatch => {
        const firebaseAnswer = await firebase.getInfluencers()
        
        if(firebaseAnswer) dispatch({
            type: RECEIVED_INFLUENCERS_LIST,
            payload: firebaseAnswer
        })
    }
}

export function newInfluencer(influencer) {
    return async dispatch => {
        const firebaseAnswer = await firebase.createInfluencer(influencer)

        if (firebaseAnswer) dispatch({
            type: ADD_NEW_INFLUENCER,
            payload: firebaseAnswer
        })
    }
}

export function editInfluencer(influencer){
    return async dispatch => {
        const firebaseAnswer = await firebase.editInfluencer(influencer)

        if(firebaseAnswer) dispatch({
            type: EDIT_INFLUENCER,
            payload: firebaseAnswer
        })
    }
}

