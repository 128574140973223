import React from 'react'
import {Animated} from 'react-animated-css'
function PublicFinal(props) {
    return (
        <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={500} animationOutDuration={500}  isVisible={props.isVisible} className="w-100p">

    
            <div className="box-inner pos-r c-white">
                <div className="box-inner__border pt-md-70 pt-50 pb-md-50 pb-40 px-25">
                    <div className="box__title fw-900 c-white lh-120 mb-0 ta-c mb-10">Вот и все</div>
                    <div className="mb-15 fw-500 fz-14 fz-sm-16 fz-md-20 ta-c mb-md-100 mb-50 box-inner__text">Спасибо за ответы!<br /> Мы проанализируем их<br /> и скоро вернемся с финальным<br /> списком инфлюенсеров</div>
                    <div className="ta-c">
                        <button className="btn-red mr-xl-5" onClick={() =>window.location.href = 'https://rta-moscow.com/'}>ОК</button>
                    </div>
                </div>
            </div>
      
        </Animated>
    )
}

export default PublicFinal