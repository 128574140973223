import React from 'react'
import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Heading, Text, TextInput, Button, toaster, Pane, Spinner, CrossIcon } from 'evergreen-ui'
import { addCompany, companyDelete } from '../store/actions/companiesActions'


function CompaniesEditor() {


    const [inputValue, setInputValue] = useState("")
    const [processing, setProcessing] = useState(false)

    const companiesSelector = useSelector(state => state.companies)

    const dispatch = useDispatch()
    const addCompanyAction = (company) => dispatch(addCompany(company))
    const deleteCompanyAction = (company) => dispatch(companyDelete(company))

    const handleCompanyAdd = async (company) => {
        if (company.trim().length === 0) {
            toaster.danger("Введите компанию")
            return
        }

        setProcessing(true)
        await addCompanyAction(company.trim())
        setProcessing(false)
        toaster.success(`Комания ${company} успешно добавлена`)
        setInputValue("")

        // await firebase.addTag(inputValue).then(tag => {
        //     const newTags = tags
        //     newTags.push(tag)
        //     setTags(newTags)
        //     setInputValue("")
        // })
    }

    const handleCompanyDelete = async (company) => {
        setProcessing(true)
        await deleteCompanyAction(company)
        setProcessing(false)
        toaster.success(`Компания ${company.value} успешно удалена`)
    }





    if (processing || companiesSelector.isLoading) return (
        <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
            <Spinner />
        </Pane>)

    return (

        <Pane padding={16} background="tint1">
            <Heading size={700} >Компании</Heading>
            {/* <Text size={500} marginBottom={20}>Для удаления компании нажмите на нее.</Text><br />
            <Text size={300} marginBottom={40}>Удаление компании приведет к исклюению её из списков фильтрации и добавления проекту.<br />Уже добавленные проекту компании останутся в карточке проекта.</Text><br /> */}
            {companiesSelector.companies.map(company => {
                return (
                    <Button appearance="minimal" height={24} intent="none" marginRight={5}>{company.value}</Button>
                )
            })}

            <div style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginTop: "20px"
            }}>
                <TextInput
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    name="AddCompane"
                    placeholder="Введите компанию..."
                    marginRight={10}
                />
                <Button onClick={handleCompanyAdd.bind(this, inputValue)} appearance="primary" intent="success">Добавить компанию</Button>
            </div>


        </Pane>




    )
}

export default CompaniesEditor