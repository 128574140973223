import React from 'react'
import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Heading, Text, TextInput, Button, toaster, Pane, Spinner, CrossIcon } from 'evergreen-ui'
import { addTag, tagDelete } from '../store/actions/tagsActions'


function TagsEditor() {


    const [inputValue, setInputValue] = useState("")
    const [processing, setProcessing] = useState(false)

    const tagsSelector = useSelector(state => state.tags)

    const dispatch = useDispatch()
    const addTagAction = (tag) => dispatch(addTag(tag))
    const deleteTagAction = (tag) => dispatch(tagDelete(tag))

    const handleTagAdd = async (tag) => {
        if (tag.trim().length === 0) {
            toaster.danger("Введите тэг")
            return
        }

        setProcessing(true)
        await addTagAction(tag.trim())
        setProcessing(false)
        toaster.success(`Тэг ${tag} успешно добавлен`)
        setInputValue("")

        // await firebase.addTag(inputValue).then(tag => {
        //     const newTags = tags
        //     newTags.push(tag)
        //     setTags(newTags)
        //     setInputValue("")
        // })
    }

    const handleTagDelete = async (tag) => {
        setProcessing(true)
        await deleteTagAction(tag)
        setProcessing(false)
        toaster.success(`Тэг ${tag.value} успешно удален`)
    }





    if (processing || tagsSelector.isLoading) return (
        <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
            <Spinner />
        </Pane>)

    return (

        <Pane padding={16} background="tint1">
            <Heading size={700} >Тэги</Heading>
            <Text size={500} marginBottom={20}>Для удаления тэга нажмите на него.</Text><br />
            <Text size={300} marginBottom={40}>Удаление тэга приведет к исклюению его из списков фильтрации и добавления тэга инфлюенсеру. <br />Уже добавленный инфлюенсеру тэг останется в карточке инфлюенсера.</Text><br />
            {tagsSelector.tags.map(tag => {
                return (
                    <Button onClick={handleTagDelete.bind(this, tag)} key={tag.id} appearance="minimal" height={24} iconBefore={CrossIcon} intent="none" marginRight={5}>{tag.value}</Button>
                )
            })}

            <div style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginTop: "20px"
            }}>
                <TextInput
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    name="AddTaf"
                    placeholder="Введите тэг..."
                    marginRight={10}
                />
                <Button onClick={handleTagAdd.bind(this, inputValue)} appearance="primary" intent="success">Добавить тэг</Button>
            </div>


        </Pane>




    )
}

export default TagsEditor