import React from 'react'
import { Heading } from 'evergreen-ui'

import CreateProjectForm from '../components/CreateProjectForm'


function CreateNewProject() {
    return (
        <React.Fragment>
            <div className="d-flex justify-content-between align-items-center mb-1">
                <Heading size={900} marginBottom={8}>Добавить проект</Heading>
            </div>
            <CreateProjectForm/>
        </React.Fragment>
    )
}

export default CreateNewProject