import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

import { toaster } from 'evergreen-ui'

const firebaseConfig = {
    apiKey: "AIzaSyBbyw1iqUh_jvi0mWOCxsELU3uiRElFzfw",
    authDomain: "rta-tinder.firebaseapp.com",
    databaseURL: "https://rta-tinder.firebaseio.com",
    projectId: "rta-tinder",
    storageBucket: "rta-tinder.appspot.com",
    messagingSenderId: "464372640029",
    appId: "1:464372640029:web:133bfaf83cbd2c171199f4"
};

class Firebase {

    constructor() {
        firebase.initializeApp(firebaseConfig)
        this.auth = firebase.auth();
        this.db = firebase.firestore();
    }

    async login(email, password) {
        const user = await firebase.auth().signInWithEmailAndPassword(email, password).catch(err => {
            toaster.danger(err.message)
        });
        return user;
    }

    async checkAuth() {
        return new Promise((resolve, reject) => {
            const unsubscribe = firebase.auth().onAuthStateChanged(user => {
                unsubscribe()
                if (user) {
                    resolve(user)
                } else {
                    resolve(false)
                }
            })
        })
    }

    // для динамического обновления
    // async setInfluencersWatch() {
    //     firebase.firestore().collection("influencers")
    //         .onSnapshot({ includeMetadataChanges: false }, function (snapshot) {
    //             const influencers = []
    //             console.log(influencers)
    //         })
    // }

    async getInfluencers() {
        const influencersArray = []

        const influencers = await firebase.firestore().collection("influencers").get().catch(err => toaster.danger(err.message))
        influencers.forEach(doc => influencersArray.push({ 'id': doc.id, ...doc.data() }))

        return influencersArray
    }
    async getSingleInfluencer(influencerID) {
        const influencer = await firebase.firestore().collection("influencers").doc(influencerID).get()
        let returnInfluencer = { id: influencer.id, ...influencer.data() }

        return returnInfluencer
    }

    async editInfluencer(influencer) {
        let newInfluencer = {}
        if (influencer.downloadURL) {
            newInfluencer = influencer
        } else {
            const storageRef = firebase.storage().ref()
            // create a child inside the storage
            const storageChild = storageRef.child(influencer.avatar.name)
            const postCover = await storageChild.put(influencer.avatar)
            const downloadURL = await storageChild.getDownloadURL()
            const fileRef = postCover.ref.location.path

            newInfluencer = {
                id: influencer.id,
                name: influencer.name,
                platform: influencer.platform,
                subscribers: influencer.subscribers,
                tags: influencer.tags,
                fileref: fileRef,
                downloadURL: downloadURL,
                comment: influencer.comment
            }
        }
        let isSuccess = false

        await firebase.firestore().collection("influencers").doc(influencer.id).set(newInfluencer)
            .then(() => isSuccess = true)
            .catch(err => toaster.danger(err.message))

        return isSuccess ? newInfluencer : false
    }

    async createInfluencer(influencer) {
        const storageRef = firebase.storage().ref()
        // create a child inside the storage
        const storageChild = storageRef.child(`${new Date().getTime()}_${influencer.avatar.name}`)
        const postCover = await storageChild.put(influencer.avatar)
        const downloadURL = await storageChild.getDownloadURL()
        const fileRef = postCover.ref.location.path

        let newInfluencer = {
            name: influencer.name,
            platform: influencer.platform,
            subscribers: influencer.subscribers,
            tags: influencer.tags,
            fileref: fileRef,
            downloadURL: downloadURL,
            comment: influencer.comment
        }

        let isSuccess = false

        await firebase.firestore().collection("influencers").add(newInfluencer)
            .then((data) => {
                newInfluencer['id'] = data.id
                isSuccess = true
            })
            .catch(err => {
                isSuccess = false
                toaster.danger(err.message)
            })

        return isSuccess ? newInfluencer : false
    }


    async getProjects() {
        const projectsArray = []

        const projects = await firebase.firestore().collection("projects").get().catch(err => toaster.danger(err.message))
        projects.forEach(doc => projectsArray.push({ 'id': doc.id, ...doc.data() }))

        return projectsArray
    }

    async getPollResults(projectID) {
        const resultsArray = []

        const results = await firebase.firestore().collection("polls").where("projectID", "==", projectID).get()
        results.forEach(doc => resultsArray.push({ 'id': doc.id, ...doc.data() }))

        return resultsArray
    }

    async createProject(project) {
        let newProject = project
        let isSuccess = false

        await firebase.firestore().collection("projects").add(project)
            .then((data) => {
                newProject['id'] = data.id
                isSuccess = true
            })
            .catch(err => {
                isSuccess = false
                toaster.danger(err.message)
            })

        if (isSuccess) {
            toaster.success(
                `Проект ${newProject.name} успешно создан.`,
                {
                    description: `influencers.rta-moscow.com/poll/${newProject.id}`,
                    duration: 10
                }
            )
            return newProject
        }
        return false
    }

    async deactivateProject(project) {
        let deactivatedProject = { ...project, status: 0 }
        let isSuccess = false

        await firebase.firestore().collection("projects").doc(project.id).set(deactivatedProject)
            .then(() => isSuccess = true)
            .catch(err => toaster.danger(err.message))

        return isSuccess ? deactivatedProject : false


    }

    async getPool(projectId) {
        const pool = await firebase.firestore().collection("projects").doc(projectId).get()
        let returnPool = { id: pool.id, ...pool.data() }

        return returnPool

    }

    async savePool(pool) {
        let newPoll = pool
        let isSuccess = false

        await firebase.firestore().collection("polls").add(pool)
            .then((data) => {
                newPoll['id'] = data.id
                isSuccess = true
            })
            .catch(err => {
                isSuccess = false
                toaster.danger(err.message)
            })

        return isSuccess ? newPoll : false
    }


    async getTags() {
        const tagsArray = []
        const tags = await firebase.firestore().collection("tags")
            .get()
            .catch(err => toaster.danger(err.message))
        tags.forEach(doc => tagsArray.push({ 'id': doc.id, ...doc.data() }))
        return tagsArray
    }


    async addTag(tag) {
        let isSuccess = false

        let addingTag = { label: tag, value: tag }
        await firebase.firestore().collection("tags").add(addingTag)
            .then((doc) => {
                isSuccess = true
                addingTag = { ...addingTag, id: doc.id }
            })
            .catch(err => toaster.danger(err.message))

        return isSuccess ? addingTag : false
    }

    async deleteTag(tag) {
        let isSuccess = false

        await firebase.firestore().collection("tags").doc(tag.id).delete()
            .then(() => isSuccess = true)
            .catch((err) => toaster.toast(err.message))
        return isSuccess ? tag : false
    }

    async getPlatforms() {
        const platformsArray = []
        const platforms = await firebase.firestore().collection("platforms")
            .get()
            .catch(err => toaster.danger(err.message))
        platforms.forEach(doc => platformsArray.push({ 'id': doc.id, ...doc.data() }))
        return platformsArray
    }


    async addPlatform(platform) {
        let isSuccess = false

        let addingPlatform = { label: platform, value: platform }
        await firebase.firestore().collection("platforms").add(addingPlatform)
            .then((doc) => {
                isSuccess = true
                addingPlatform = { ...addingPlatform, id: doc.id }
            })
            .catch(err => toaster.danger(err.message))

        return isSuccess ? addingPlatform : false
    }

    async deletePlatform(platform) {
        let isSuccess = false

        await firebase.firestore().collection("platforms").doc(platform.id).delete()
            .then(() => isSuccess = true)
            .catch((err) => toaster.toast(err.message))
        return isSuccess ? platform : false
    }

    async getCompanies() {
        const companiesArray = []
        const companies = await firebase.firestore().collection("companies")
            .get()
            .catch(err => toaster.danger(err.message))
        companies.forEach(doc => companiesArray.push({ 'id': doc.id, ...doc.data() }))
        return companiesArray
    }


    async addCompany(company) {
        let isSuccess = false
        let addingCompany = { label: company, value: company }

        await firebase.firestore().collection("companies").add(addingCompany)
            .then((doc) => {
                isSuccess = true
                addingCompany = { ...addingCompany, id: doc.id }
            })
            .catch(err => toaster.danger(err.message))

        return isSuccess ? addingCompany : false
    }

    async deleteCompany(company) {
        let isSuccess = false

        await firebase.firestore().collection("companies").doc(company.id).delete()
            .then(() => isSuccess = true)
            .catch((err) => toaster.toast(err.message))
        return isSuccess ? company : false
    }

    async getPollsForInfluencer(influencerID) {
        let result = []

        await firebase.firestore().collection("polls")
            .where("influencers", "array-contains", influencerID)
            .get()
            .then(docs => docs.forEach(doc => result.push({ id: doc.id, ...doc.data() })))

        if (result.length !== 0) return result
        return result
    }

    async getPollsForCompany(companyID, influencerID) {
        let result = []
        if (!influencerID) {
            console.log(1)
            await firebase.firestore().collection("polls")
                .where("companyID", "==", companyID)
                .get()
                .then(docs => docs.forEach(doc => result.push({ id: doc.id, ...doc.data() })))
        } else {
            await firebase.firestore().collection("polls")
                .where("companyID", "==", companyID).where("influencers", "array-contains", influencerID)
                .get()
                .then(docs => docs.forEach(doc => result.push({ id: doc.id, ...doc.data() })))
        }

        console.log(result)
        if (result.length !== 0) return result
        return result
    }
}


export default new Firebase()