import React, { useState } from 'react'
import { Avatar, Table, SelectMenu, TextDropdownButton, Popover, Position, Text, Pane, TextInputField, Heading, Select, IconButton, ChevronLeftIcon, ChevronRightIcon } from 'evergreen-ui'
import { useSelector } from 'react-redux'
import { filter } from 'fuzzaldrin-plus'

import InfluencerInfoSideSheet from '../components/InfluencerInfoSideSheet'


function getTagsStr(tagArray) {
    let tagsString = tagArray.join(', ')
    if (tagsString.length > 50) {
        return tagsString.substr(0, 50) + '...'
    } else {
        return tagsString
    }
}

function InfluencersList() {

    const [searchQuery, setSearchQuery] = useState("")
    const [selectedPlatforms, setSelectedPlatform] = useState([])
    const [selectedTags, setSelectedTags] = useState([])
    const [subFrom, setSubFrom] = useState(0)
    const [subUtil, setSubUtil] = useState(10000000000)
    const [startPos, setStartPos] = useState(0)
    const [paginationFilterLength, setPaginationFilterLength] = useState(20)

    const [isSingleInfShown, setIsSingleInfShown] = useState(false)
    const [singeShowInfluencer, setSingleShowInfluencer] = useState("")


    const influencersSelector = useSelector(state => state.influencers)
    const tagsSelector = useSelector(state => state.tags)
    const platformsSelector = useSelector(state => state.platforms)


    const filterByName = influencers => {
        if (searchQuery.length === 0) return influencers

        return influencers.filter(influencer => {
            const result = filter([influencer.name], searchQuery)
            return result.length === 1
        })
    }

    const sortByPlatform = influencers => {
        if (selectedPlatforms.length === 0) return influencers

        return influencers.filter(influencer => {
            return selectedPlatforms.some((platform) => {
                return !!~influencer.platform.map(p => p.value).indexOf(platform)
            })
        })
    }

    const sortByTags = influencers => {
        if (selectedTags.length === 0) return influencers

        return influencers.filter(influencer => {
            return selectedTags.every((tag) => {
                return !!~influencer.tags.indexOf(tag)
            })
        })
    }

    const sortBySubs = (influencers => {
        return influencers.filter(influencer => influencer.subscribers >= subFrom && influencer.subscribers <= subUtil)
    })

    const paginationFilter = (influencers) => {
        return influencers.slice(startPos, startPos + paginationFilterLength)
    }

    const renderPlatforms = (data) => {
        const sliced = data.slice(0, 3)
        const p = sliced.reduce((sum, curret, index) => {
            return sum + (index < sliced.length - 1 ? `${curret.value}, ` : curret.value)
        }, '')

        return p
    }

    const renderPlatformHeaderCell = () => {
        return (
            <Table.HeaderCell>
                <SelectMenu
                    isMultiSelect
                    title="Выбор платформы"
                    filterPlaceholder={"Введите платформу..."}
                    options={platformsSelector.platforms}
                    selected={selectedPlatforms}
                    height={305}
                    onSelect={item => {
                        const selectedItems = [...selectedPlatforms, item.value]
                        setSelectedPlatform(selectedItems)
                        setStartPos(0)
                    }}
                    onDeselect={item => {
                        const deselectedItemIndex = selectedPlatforms.indexOf(item.value)
                        const selectedItems = selectedPlatforms.filter(
                            (_item, i) => i !== deselectedItemIndex
                        )
                        setSelectedPlatform(selectedItems)
                        setStartPos(0)
                    }}
                >
                    <TextDropdownButton>Платформа</TextDropdownButton>
                </SelectMenu>
            </Table.HeaderCell>
        )
    }

    const renderTagsHeaderCell = () => {
        return (
            <Table.HeaderCell>
                <SelectMenu
                    isMultiSelect
                    title="Выбор тэгов"
                    filterPlaceholder={"Введите тэги..."}
                    options={tagsSelector.tags}
                    height={305}
                    selected={selectedTags}
                    onSelect={item => {
                        const selectedItems = [...selectedTags, item.value]
                        setSelectedTags(selectedItems)
                        setStartPos(0)
                    }}
                    onDeselect={item => {
                        const deselectedItemIndex = selectedTags.indexOf(item.value)
                        const selectedItems = selectedTags.filter(
                            (_item, i) => i !== deselectedItemIndex
                        )
                        setSelectedTags(selectedItems)
                        setStartPos(0)
                    }}
                >
                    <TextDropdownButton>Тэги</TextDropdownButton>
                </SelectMenu>
            </Table.HeaderCell>
        )
    }

    const renderSubHeaderCell = () => {
        return (
            <Table.HeaderCell>
                <Popover
                    position={Position.BOTTOM_LEFT}
                    content={({ close }) => (
                        <Pane
                            width={240}
                            height={240}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            flexDirection="column"
                        >
                            <TextInputField
                                label="От"
                                placeholder="0..."
                                value={subFrom}
                                onChange={e => setSubFrom(e.target.value)}
                            />
                            <TextInputField
                                label="До"
                                placeholder="1000000..."
                                value={subUtil}
                                onChange={e => {
                                    setSubUtil(e.target.value)
                                    setStartPos(0)
                                }}
                            />
                        </Pane>
                    )}>
                    <TextDropdownButton>Подписчики</TextDropdownButton>
                </Popover>
            </Table.HeaderCell>
        )
    }

    const renderRow = (influencer) => {
        return (
            <Table.Row key={influencer.id} isSelectable onSelect={() => {
                setSingleShowInfluencer(influencer)
                setIsSingleInfShown(true)
            }}>
                <Table.Cell>
                    <Avatar name={influencer.name} />
                    <Text marginLeft={8} size={300} fontWeight={500}>
                        {influencer.name}
                    </Text>
                </Table.Cell>
                <Table.TextCell>{renderPlatforms(influencer.platform)}</Table.TextCell>
                <Table.TextCell>{getTagsStr(influencer.tags)}</Table.TextCell>
                <Table.TextCell isNumber>{influencer.subscribers}</Table.TextCell>
            </Table.Row>
        )
    }
    const filtredItems = filterByName(sortBySubs(sortByPlatform(sortByTags(influencersSelector.influencers))))
    const showingItems = paginationFilter(filtredItems)

    return (
        <React.Fragment>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '5px'
                }}
            >
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                }}>
                    <Heading size={500}>Показаны {showingItems.length} из {influencersSelector.influencers.length}</Heading>
                </div>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                >
                    <Heading size={500} marginRight="10px">Страница {Math.ceil(startPos / paginationFilterLength) + 1} и {Math.ceil(filtredItems.length / paginationFilterLength)}</Heading>

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                        }}
                    >
                        <IconButton height={24} marginRight={3} icon={ChevronLeftIcon}
                            onClick={() => {
                                if (startPos - paginationFilterLength >= 0) setStartPos(startPos - paginationFilterLength)
                            }}
                        />
                        <IconButton height={24} icon={ChevronRightIcon}
                            onClick={() => {
                                if (startPos + paginationFilterLength < filtredItems.length)
                                    setStartPos(startPos + paginationFilterLength)
                            }}
                        />
                    </div>
                </div>
            </div>


            <InfluencerInfoSideSheet
                isSingleInfShown={isSingleInfShown}
                onCloseComplete={() => setIsSingleInfShown(false)}
                influencer={singeShowInfluencer}
            />
            <Table border>
                <Table.Head>
                    <Table.SearchHeaderCell
                        placeholder='Поиск по имени...'
                        onChange={value => {
                            setSearchQuery(value.trim())
                            setStartPos(0)
                        }}
                    />
                    {renderPlatformHeaderCell()}
                    {renderTagsHeaderCell()}
                    {renderSubHeaderCell()}
                </Table.Head>
                <Table.Body>
                    {showingItems.map(influencer => renderRow(influencer))}
                </Table.Body>
            </Table >
        </React.Fragment>

    )
}

export default InfluencersList