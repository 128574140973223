import React from 'react'
import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Heading, Text, TextInput, Button, toaster, Pane, Spinner, CrossIcon } from 'evergreen-ui'
import { addPlatform, platformDelete } from '../store/actions/platformsActions'


function PlatformsEditor() {

    
    const [inputValue, setInputValue] = useState("")
    const [processing, setProcessing] = useState(false)

    const platformsSelector = useSelector(state => state.platforms)

    const dispatch = useDispatch()
    const addPlatformAction = (platform) => dispatch(addPlatform(platform))
    const deletePlatformAction = (platform) => dispatch(platformDelete(platform))

    const handlePlatformAdd = async (platform) => {
        if (platform.trim().length === 0) {
            toaster.danger("Введите платформу...")
            return
        }

        setProcessing(true)
        await addPlatformAction(platform.trim())
        setProcessing(false)
        toaster.success(`Платформа ${platform} успешно добавлена`)
        setInputValue("")


    }

    const handlePlatformDelete = async (platform) => {
        setProcessing(true)
        await deletePlatformAction(platform)
        setProcessing(false)
        toaster.success(`Платформа ${platform.value} успешно удалена`)
    }




    if (processing || platformsSelector.isLoading) return (
        <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
            <Spinner />
        </Pane>)

    return (

        <Pane padding={16} background="tint1">
            <Heading size={700} >Платформы</Heading>
            <Text size={500} marginBottom={20}>Для удаления платформы нажмите на неё.</Text><br />
            <Text size={300} marginBottom={40}>Удаление платформы приведет к исклюению её из списков фильтрации и добавления платформы инфлюенсеру. <br />Уже добавленная инфлюенсеру платформа останется в карточке инфлюенсера.</Text><br />
            {platformsSelector.platforms.map(platform => {
                return (
                    <Button onClick={handlePlatformDelete.bind(this, platform)} key={platform.id} appearance="minimal" height={24} iconBefore={CrossIcon} intent="none" marginRight={5}>{platform.value}</Button>
                )
            })}

            <div style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginTop: "20px"
            }}>
                <TextInput
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    name="AddPlatform"
                    placeholder="Введите тэг..."
                    marginRight={10}
                />
                <Button onClick={handlePlatformAdd.bind(this, inputValue)} appearance="primary" intent="success">Добавить платформу</Button>
            </div>


        </Pane>




    )
}

export default PlatformsEditor