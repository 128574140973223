import React, { useState, useEffect } from 'react'
import { Heading, toaster, Pane, Spinner } from 'evergreen-ui'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import CreateInfluencerForm from '../components/CreateInfluencerForm'
import PreviewInfluencer from '../components/PreviewInfluencer'
import { editInfluencer } from '../store/actions/influencersActions'



function EditInfluencerPage(props) {

    const [processing, setProcessing] = useState(false)
    const [id, setId] = useState("")
    const [name, setName] = useState("")
    const [subscribers, setSubscribers] = useState("")
    const [baseAvatar, setBaseAvatar] = useState("")
    const [avatar, setAvatar] = useState("")
    const [selectedTags, setSelectedTags] = useState([])
    const [selectedPlatforms, setSelectedPlatforms] = useState([])
    const [comment, setComment] = useState('')

    const tagsSelector = useSelector(state => state.tags)
    const platformsSelector = useSelector(state => state.platforms)
    const influencersSelector = useSelector(state => state.influencers)


    const dispatch = useDispatch()
    const editInfluencerAction = (influencer, isPhotoEdited) => dispatch(editInfluencer(influencer, isPhotoEdited))

    const history = useHistory()

    useEffect(() => {
        if (!influencersSelector.isLoading) {
            const influencer = influencersSelector.influencers.find(influencer => influencer.id === props.match.params.influencerID)
            setStartValues(influencer)
        }
    }, [influencersSelector.isLoading]);

    const setStartValues = (influencer) => {

        setId(influencer.id)
        setName(influencer.name)
        setSubscribers(influencer.subscribers)
        setBaseAvatar(influencer.downloadURL)
        setSelectedTags(influencer.tags)
        setSelectedPlatforms(influencer.platform)
        setComment(influencer.comment)
    }


    const addInfluencer = async (e) => {
        e.preventDefault()

        let hasError = false

        if (selectedPlatforms.length === 0) {
            toaster.danger("Не выбрана платформа")
            hasError = true
        }

        if (selectedTags.length === 0) {
            toaster.danger("Не выбраны теги")
            hasError = true
        }

        if (subscribers.length === 0) {
            toaster.danger("Подписчики не введены")
            hasError = true
        }

        if (name.length === 0) {
            toaster.danger("Имя не введено")
            hasError = true
        }



        if (hasError) return
        //Полными объектами
        // let platformObj = platformsSelector.platforms.find(platform => platform.value === selectedPlatforms)
        // let tagsObjArr = tagsSelector.tags.filter(tag => !!~selectedTags.indexOf(tag.value))

        let influencerData = {
            id,
            name,
            subscribers,
            platform: selectedPlatforms,
            tags: selectedTags,
            comment: comment || ''
        }
        avatar ? influencerData["avatar"] = avatar : influencerData["downloadURL"] = baseAvatar

        setProcessing(true)
        await editInfluencerAction(influencerData)/////костыль!!!!!!!!!
        setProcessing(false)
        toaster.success(`Инфлюенсен ${influencerData.name} изменен.`)
        history.push('/admin/influencers')

    }

    const setPlatformLink = (item) => {
        const newSelectedPlatforms = selectedPlatforms.map(platform => (
            platform.value === item.value
                ? { ...platform, link: item.link }
                : platform
        ))
        setSelectedPlatforms(newSelectedPlatforms)
    }

    if (influencersSelector.isLoading || processing) return (
        <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
            <Spinner />
        </Pane>
    )

    return (
        <React.Fragment>

            <div style={{
                marginBottom: "10px",
                marginTop: "50px"
            }}>
                <Heading size={900} marginBottom={8}>Редактировать инфлюенсера</Heading>
            </div>

            <div style={{
                display: "flex",
                justifyContent: "space-between"
            }}>
                <CreateInfluencerForm
                    name={name}
                    subscribers={subscribers}
                    avatar={avatar}
                    selectedTags={selectedTags}
                    selectedPlatforms={selectedPlatforms}
                    comment={comment}

                    tagsSelector={tagsSelector}
                    platformsSelector={platformsSelector}

                    setName={item => setName(item)}
                    setSubscribers={item => setSubscribers(item)}
                    setAvatar={item => setAvatar(item)}
                    setSelectedTags={item => setSelectedTags(item)}
                    setSelectedPlatforms={item => setSelectedPlatforms(item)}
                    setComment={comment => setComment(comment)}
                    setPlatformLink={item => setPlatformLink(item)}

                    addInfluencer={e => addInfluencer(e)}
                />


                <PreviewInfluencer
                    colors={[{ hex: "#F8006C" }, { hex: "#9A00F8" }]}
                    name={name}
                    subscribers={subscribers}
                    platform={selectedPlatforms}
                    category={selectedTags}
                    downloadURL={avatar ? URL.createObjectURL(avatar) : baseAvatar}
                />
            </div>


        </React.Fragment>
    )
}

export default EditInfluencerPage