import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { deactivateProject } from '../store/actions/projectsAction'
import { Heading, Badge, Avatar, Tooltip, Button, Pane, Spinner, Code, toaster, Table } from 'evergreen-ui'

import { ExportCSV } from '../components/ExcelExportButton'
import firebase from '../firebase/config'
import PollResults from '../components/PollResults'


function formatDate(date) {

    var dd = date.getDate();
    if (dd < 10) dd = '0' + dd;

    var mm = date.getMonth() + 1;
    if (mm < 10) mm = '0' + mm;

    var yy = date.getFullYear() % 100;
    if (yy < 10) yy = '0' + yy;

    return dd + '-' + mm + '-' + yy;
}

function getWorldFromScore(score) {
    switch (score) {
        case 1:
            return "Лайк"

        case 0:
            return "Не знаю"

        case -1:
            return "Не нравится"

        default:
            return "Ошибка"
    }
}


function SingleProject(props) {

    const [projectPolls, setProjectPolls] = useState("")
    const [projectInfo, setProjectInfo] = useState("")
    const [processing, setProcessing] = useState(false)

    const [searchQuery, setSearchQuery] = useState("")
    const [influencerFilter, setInfluencerFilter] = useState([])



    const dispatch = useDispatch()
    const deactivateProjectAction = (project) => dispatch(deactivateProject(project))

    const projectsSelector = useSelector(state => state.projects)


    const projectLink = `influencers.rta-moscow.com/poll/${props.match.params.projectID}`

    const getStatus = (status) => status ? <Badge color="green">Активен</Badge> : <Badge color="red">Не активен</Badge>

    useEffect(() => {
        if (!projectsSelector.isLoading) {
            const projectsInfo = projectsSelector.projects.find(project => project.id === props.match.params.projectID)
            setProjectInfo(projectsInfo)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectsSelector])

    useEffect(() => {
        getProjectPool(props.match.params.projectID)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getProjectPool = async (projectID) => {
        await firebase.getPollResults(projectID).then(polls => setProjectPolls(polls))
    }

    const prepareDataToExport = (polls) => {
        const returnData = []
        polls.map(poll => {
            const returnPoll = {
                'Имя': poll.name,
                'Фамилия': poll.surname,
                'Должность': poll.position
            }
            poll.votes.map(vote => {
                returnPoll[vote.name] = getWorldFromScore(vote.score)
            })
            returnData.push(returnPoll)

        })
        return returnData
    }

    const deactivateProjectHandler = async () => {
        setProcessing(true)
        await deactivateProjectAction(projectInfo)
        toaster.success(`Проект ${projectInfo.name} остановлен.`)
        setProcessing(false)
    }

    const renderInfluencer = (influencer) => {
        return (
            <Tooltip key={influencer.id} content={influencer.name}>
                <Avatar
                    name={influencer.name}
                    src={influencer.downloadURL}
                    size={105}
                    margin={10}

                />
            </Tooltip>
        )
    }


    if (projectsSelector.isLoading || !projectInfo || processing) return (
        <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
            <Spinner />
        </Pane>

    )

    return (
        <React.Fragment>
            <div style={{
                display: "flex",
                justifyContent: "flex-start",
                marginBottom: "15px",
                wordWrap: "anywhere"

            }}>
                <Heading size={800}>{projectInfo.name} {getStatus(projectInfo.status)}</Heading>
            </div>

            <Code size={400}>{projectLink}</Code >
            <Heading marginTop={5} heading={600}>Компания: {projectInfo.company}</Heading>
            <Heading marginTop={5} heading={600}>Участников: {projectPolls ? projectPolls.length : 0}</Heading>
            <div style={{
                marginTop: "15px",
                display: "flex"
            }}>
                {
                    projectPolls
                        ? projectPolls.length > 0
                            ? <ExportCSV csvData={prepareDataToExport(projectPolls)} fileName={`${projectInfo.name}-${formatDate(new Date())}`} />
                            : null
                        : null
                }
                {
                    projectInfo
                        ? projectInfo.status
                            ? <Button onClick={deactivateProjectHandler} marginRight={16} appearance="primary" intent="danger">Завершить</Button>
                            : null
                        : null
                }

            </div>
            <Heading size={700} marginTop={40} marginBottom={10}>Инфлюенсеры</Heading>
            <div style={{
                display: "flex",
                flexWrap: "wrap"
            }}>
                {projectInfo.influencers.map(influencer => renderInfluencer(influencer))}
            </div>
            {projectPolls
                ? < PollResults
                    polls={projectPolls}
                    setInfluencerFilter={(influencers) => setInfluencerFilter(influencers)}
                    influencerFilter={influencerFilter}
                    setSearchQuery={(query) => setSearchQuery(query)}
                    searchQuery={searchQuery}
                />
                : null

            }

        </React.Fragment>
    )
}

export default SingleProject