import React, { useState } from 'react'
import { Badge, Table, SelectMenu, TextDropdownButton, Text, Heading } from 'evergreen-ui'
import { useSelector } from 'react-redux'
import { filter } from 'fuzzaldrin-plus'
import { useHistory } from 'react-router-dom'


function getStatusFromBool(boolStatus) {
    return boolStatus ? 'Активен' : 'Не активен'
}

function ProjectsList() {

    const statusList = [{ label: 'Активен', value: 'Активен' }, { label: 'Не активен', value: 'Не активен' }]

    const [searchQuery, setSearchQuery] = useState("")
    const [selectedStatus, setSelectedStatus] = useState([])
    const [selectedCompany, setSelectedCompany] = useState([])

    const projectsSelector = useSelector(state => state.projects)
    const companiesSelector = useSelector(state => state.companies)

    const history = useHistory()



    const filterByName = projects => {
        if (searchQuery.length === 0) return projects

        return projects.filter(project => {
            const result = filter([project.name], searchQuery)
            return result.length === 1
        })
    }

    const filterByStatus = projects => {
        if (selectedStatus.length === 0) return projects

        return projects.filter(project => !!~selectedStatus.indexOf(getStatusFromBool(project.status)))
    }

    const filterByCompany = projects => {
        if (selectedCompany.length === 0) return projects

        return projects.filter(project =>  !!~selectedCompany.indexOf(project.company))
    }

    const getTagForTextStatus = (status) => {
        if (status === 'Активен') {
            return (
                <Badge color="green">Активен</Badge>
            )
        } else {
            return (
                <Badge color="red">Не активен</Badge>
            )
        }
    }


    const renderRow = (project) => {
        return (
            <Table.Row key={project.id} isSelectable onSelect={() => {
                history.push(`/admin/projects/${project.id}`)
            }}>
                <Table.Cell>
                    <Text marginLeft={8} size={300} fontWeight={500}>
                        {project.name}
                    </Text>
                </Table.Cell>
                <Table.Cell>
                    <Text marginLeft={8} size={300} fontWeight={500}>
                        {project.company}
                    </Text>
                </Table.Cell>
                <Table.TextCell>{getTagForTextStatus(getStatusFromBool(project.status))}</Table.TextCell>
            </Table.Row>
        )
    }


    const filtredItems = filterByName(filterByStatus(filterByCompany(projectsSelector.projects)))
    return (
        <React.Fragment>
            <Heading size={500} marginBottom={10}>Показаны {filtredItems.length} из {projectsSelector.projects.length}</Heading>

            <Table border>
                <Table.Head>
                    <Table.SearchHeaderCell
                        placeholder='Поиск по названию...'
                        onChange={value => setSearchQuery(value.trim())}
                    />
                    <Table.HeaderCell>
                        <SelectMenu
                            isMultiSelect
                            title="Выбор компании"
                            hasFilter={false}
                            options={companiesSelector.companies}
                            height={110}
                            selected={selectedCompany}
                            onSelect={item => {
                                const selectedItems = [...selectedCompany, item.value]
                                setSelectedCompany(selectedItems)
                            }}
                            onDeselect={item => {
                                const deselectedItemIndex = selectedCompany.indexOf(item.value)
                                const selectedItems = selectedStatus.filter(
                                    (_item, i) => i !== deselectedItemIndex
                                )
                                setSelectedCompany(selectedItems)
                            }}
                        >
                            <TextDropdownButton>Компания</TextDropdownButton>
                        </SelectMenu>
                    </Table.HeaderCell>

                    <Table.HeaderCell>
                        <SelectMenu
                            isMultiSelect
                            title="Выбор статуса"
                            hasFilter={false}
                            options={statusList}
                            height={110}
                            selected={selectedStatus}
                            onSelect={item => {
                                const selectedItems = [...selectedStatus, item.value]
                                setSelectedStatus(selectedItems)
                            }}
                            onDeselect={item => {
                                const deselectedItemIndex = selectedStatus.indexOf(item.value)
                                const selectedItems = selectedStatus.filter(
                                    (_item, i) => i !== deselectedItemIndex
                                )
                                setSelectedStatus(selectedItems)
                            }}
                        >
                            <TextDropdownButton>Статус</TextDropdownButton>
                        </SelectMenu>
                    </Table.HeaderCell>
                </Table.Head>
                <Table.Body>
                    {filtredItems.map(project => renderRow(project))}
                </Table.Body>
            </Table>
        </React.Fragment>
    )
}

export default ProjectsList