import React, { useState } from 'react'
import { Heading, TextInputField, Button } from 'evergreen-ui'
import { Redirect } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'

import { loginUser } from '../store/actions/auth'




function LoginPage() {

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [redirect, setRedirect] = useState(false)

    const authSelector = useSelector(state => state.auth)

    const dispatch = useDispatch()
    const logInUserAction = (email, password) => dispatch(loginUser(email, password))

    const login = async (e) => {
        e.preventDefault()

        if (email.trim() !== "" && password.trim() !== "") {
            let user = await logInUserAction(email, password)
            if (user) {
                setRedirect(true)
            }
        }
    }

    if (redirect) {
        return <Redirect to="/admin/influencers" />
    }

    return (
        <div style={{
            display: "flex",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center"
        }}>
            {!authSelector.isLoading && authSelector.user
                ? <Redirect to="/admin/influencers" />
                : null

            }
            <form
                style={{
                    textAlign: "center"
                }}
                onSubmit={login}
            >
                <Heading size={900} >Авторизация</Heading>


                <div style={{
                    textAlign: "left"
                }}>
                    <TextInputField
                        label="Логин"
                        placeholder="admin"
                        marginTop="10px"
                        width={300}
                        type="email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextInputField
                        label="Пароль"
                        placeholder="password"
                        type="password"
                        width={300}
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>


                <Button
                    appearance="primary"
                    type="submit"
                    intent="success"
                    height={40}
                    onClick={login}
                >
                    Войти
                </Button>
            </form>

        </div>
    )
}

export default LoginPage