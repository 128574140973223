import React, { useState } from 'react'
import { Dialog, Autocomplete, TextInput, Position, Button, Heading, Avatar, SelectMenu, Text, toaster } from 'evergreen-ui'
import { useSelector } from 'react-redux'

function AddInfluencerPop(props) {

    const [selectedInfluencerName, setSelectedInfluencerName] = useState("")
    const [selectedCategories, setSelectedCategories] = useState([])
    const [selectedTags, setSelectedTags] = useState([])
    const [selectedPlatforms, setSelectedPlatforms] = useState([])

    const influencersSelector = useSelector(state => state.influencers)

    const onDialogConfirm = (close) => {
        let selectedInfluencer = influencersSelector.influencers.find(influencer => influencer.name === selectedInfluencerName)

        if (selectedTags.length === 0) {
            toaster.danger("Выберите хотя бы один тэг")
            return
        }

        if (selectedPlatforms.length === 0) {
            toaster.danger("Выберите хотя бы одну платформу")
            return
        }

        if (selectedCategories.length === 0) {
            toaster.danger("Выберите хотя бы одну категорию")
            return
        }


        let returnInfluencer = {
            id: selectedInfluencer.id,
            name: selectedInfluencer.name,
            subscribers: selectedInfluencer.subscribers,
            downloadURL: selectedInfluencer.downloadURL,
            platform: selectedPlatforms,
            category: selectedCategories,
            hashTags: selectedTags
        }

        props.onInfluencerAdd(returnInfluencer)
        close()
    }

    const renderPlatforms = (data) => {
        const platforms = data.map((p, index) => (
            <a style={{
                wordWrap: "anywhere",
                cursor: "pointer",
                color: "#234361"
            }}
                target="_blank"
                href={p.link ? p.link : null}
                key={index}
            >
                {index < data.length - 1 ? `${p.value}, ` : p.value}
            </a>
        ))

        return platforms
    }

    const showInfluencerInfo = (influencerName) => {
        let selectedInfluencer = influencersSelector.influencers.find(influencer => influencer.name === influencerName)
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    marginTop: "20px"
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center"
                    }}
                >
                    <Avatar
                        src={selectedInfluencer.downloadURL}
                        size={80}
                        marginRight={10}

                    />
                    <div>
                        <Heading size={600}>Подписчики: {selectedInfluencer.subscribers}</Heading>
                    </div>
                </div>
                <SelectMenu
                    isMultiSelect
                    title="Категории инфлюенсера"
                    options={selectedInfluencer.tags.map(item => { return { value: item, label: item } })}
                    selected={selectedCategories}
                    filterPlaceholder={"Введите тэг..."}
                    height={250}
                    onSelect={item => {
                        const selectedItems = [...selectedCategories, item.value]
                        setSelectedCategories(selectedItems)
                    }}
                    onDeselect={item => {
                        const deselectedItemIndex = selectedCategories.indexOf(item.value)
                        const selectedItems = selectedCategories.filter(
                            (_item, i) => i !== deselectedItemIndex
                        )
                        setSelectedCategories(selectedItems)
                    }}

                >
                    <Button marginTop={25}>Выбрать категории</Button>
                </SelectMenu>
                <Text>{selectedCategories.join(', ')}</Text>
                <SelectMenu
                    isMultiSelect
                    title="Платформы инфлюенсера"
                    options={selectedInfluencer.platform.map(item => { return { value: item.value, label: item.value, link: item.link } })}
                    selected={selectedPlatforms.map(p => p.value)}
                    filterPlaceholder={"Введите платформу..."}
                    height={250}
                    onSelect={item => {
                        const selectedItems = [...selectedPlatforms, { value: item.value, link: item.link }]
                        setSelectedPlatforms(selectedItems)
                    }}
                    onDeselect={item => {
                        const deselectedItemIndex = selectedPlatforms.map(p => p.value).indexOf(item.value)
                        const selectedItems = selectedPlatforms.filter(
                            (_item, i) => i !== deselectedItemIndex
                        )
                        setSelectedPlatforms(selectedItems)
                    }}

                >
                    <Button marginTop={25}>Выбрать платформы</Button>
                </SelectMenu>
                <Text>{renderPlatforms(selectedPlatforms)}</Text>

                <SelectMenu
                    isMultiSelect
                    title="Тэги инфлюенсера"
                    options={selectedInfluencer.tags.map(item => { return { value: item, label: item } })}
                    selected={selectedTags}
                    filterPlaceholder={"Введите тэг..."}
                    height={250}
                    onSelect={item => {
                        const selectedItems = [...selectedTags, item.value]
                        setSelectedTags(selectedItems)
                    }}
                    onDeselect={item => {
                        const deselectedItemIndex = selectedTags.indexOf(item.value)
                        const selectedItems = selectedTags.filter(
                            (_item, i) => i !== deselectedItemIndex
                        )
                        setSelectedTags(selectedItems)
                    }}

                >
                    <Button marginTop={25}>Выбрать хэштэги</Button>
                </SelectMenu>
                <Text>{selectedTags.join(', ')}</Text>
            </div>
        )
    }

    return (
        <Dialog
            isShown={props.isShown}
            title="Добавить инфлюенсера"
            confirmLabel="Добавить"
            cancelLabel="Закрыть"
            intent="success"
            onCloseComplete={() => {
                setSelectedInfluencerName("")
                props.closeDialog()
            }}
            onConfirm={(close => onDialogConfirm(close))}
        >
            <Autocomplete
                title="Инфлюенсеры"
                onChange={(changedItem) => {
                    setSelectedTags([])
                    setSelectedPlatforms([])
                    setSelectedCategories([])
                    setSelectedInfluencerName(changedItem)
                }}
                position={Position.BOTTOM_LEFT}
                items={influencersSelector.influencers.map(item => item.name)}
            >
                {({
                    getInputProps,
                    getRef,
                    inputValue,
                }) => (
                    <TextInput
                        placeholder="Имя Фамилия..."
                        value={inputValue}
                        width="100%"
                        ref={getRef}
                        {...getInputProps()}
                    />


                )}
            </Autocomplete>
            {selectedInfluencerName ? showInfluencerInfo(selectedInfluencerName) : null}
        </Dialog>
    )
}

export default AddInfluencerPop