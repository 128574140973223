import React from 'react'
import { Heading, Button, PlusIcon } from 'evergreen-ui'
import InfluencersList from '../components/InfluencersList'
import { Link } from 'react-router-dom'

function InfluencersListPage() {
    return (
        <React.Fragment>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "10px",
                    marginTop:"50px"
                }}
            >
                <Heading size={900} marginBottom={8}>Инфлюенсеры</Heading>
                <Link to="/admin/influencers/new"><Button iconBefore={PlusIcon} height={40} appearance="primary" intent="success">Добавить инфлюенсера</Button></Link>
            </div>

            <InfluencersList />
        </React.Fragment>


    )
}

export default InfluencersListPage