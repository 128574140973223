import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Spinner, Pane, Heading, Table } from 'evergreen-ui'
import { Link } from 'react-router-dom'
import { filter } from 'fuzzaldrin-plus'
import { useHistory } from 'react-router-dom'

function CompaniesPage() {

    const [searchQuery, setSearchQuery] = useState("")
    const companiesSelector = useSelector(state => state.companies)
    const history = useHistory()


    if (companiesSelector.isLoading) return (
        <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
            <Spinner />
        </Pane>
    )

    const filterByName = companies => {
        if (searchQuery.length === 0) return companies

        return companies.filter(company => {
            const result = filter([company.label], searchQuery)
            return result.length === 1
        })
    }

    const filtredCompanies = filterByName(companiesSelector.companies)
    return (
        <React.Fragment>
            <Heading size={900} marginBottom={10}>Компании</Heading>
            <Table border>
                <Table.Head>
                    <Table.SearchHeaderCell
                        onChange={value => setSearchQuery(value)}
                        placeholder='Искать по названию...'
                    />
                </Table.Head>
                <Table.Body>
                    {filtredCompanies.map(company => (
                        <Table.Row isSelectable key={company.id} onSelect={()=>history.push(`/admin/companies/${company.id}`)}>
                            <Table.Cell>{company.label}</Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </React.Fragment>
    )
}

export default CompaniesPage