import {combineReducers} from 'redux'


import authReducer from './authReducer'
import influencersReducer from './influencersReducer'
import tagReducer from './tagReducer'
import platformReducer from './platformReducer'
import projectsReducer from './projectsReducer'
import publicReducer from './publicReducer'
import companiesReducer from './companiesReducer'



export default combineReducers({
    auth: authReducer,
    influencers: influencersReducer,
    projects: projectsReducer,
    tags: tagReducer,
    platforms: platformReducer,
    pool: publicReducer,
    companies: companiesReducer

})