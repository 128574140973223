import { RECEIVED_PLATFORMS, ADD_PLATFORM, DELETED_PLATFORM} from '../actions/actionTypes'
const initialState = {
    platforms: [],
    isLoading: true,
    hasError: false
}

export default function platformReducer(state = initialState, action) {
    switch (action.type) {
        case RECEIVED_PLATFORMS:
            return { ...state, platforms: [...action.payload], isLoading: false }
        case ADD_PLATFORM:
            return { ...state, platforms: [...state.platforms, action.payload] }
        case DELETED_PLATFORM:
            return { ...state, platforms: [...state.platforms.filter(platform => platform.id !== action.payload.id)] }
        default:
            return state
    }
}