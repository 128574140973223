import { AUTH_SUCCESS, AUTH_CHECKED, AUTH_UNSUCCESS } from '../actions/actionTypes'
const initialState = {
    user:{},
    isLoading: true,
    hasError: false
}

export default function authReducer(state = initialState, action) {
    switch (action.type) {
        case AUTH_SUCCESS:
            return {
                ...state, user: action.payload, isLoading: false
            }
        case AUTH_CHECKED:
            return {...state, user: action.payload, isLoading:false}
        case AUTH_UNSUCCESS:
            return{...state, isLoading: false}
        default:
            return state
    }
}