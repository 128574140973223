import React, { useState } from 'react'
import { Pane, Tablist, Tab } from 'evergreen-ui'
import TagsEditor from '../components/TagsEditor'
import PlatformsEditor from '../components/PlatformsEditor'
import CompaniesEditor from '../components/CompanyEditor'


function SettingsPage() {

    const [selectedTab, setSelectedTab] = useState(0)
    const tabs = ["Тэги", "Платформы", "Компании"]
    const tabsInline = [<TagsEditor />, <PlatformsEditor />, <CompaniesEditor/>]

    return (
        <Pane>
            <Tablist
                marginBottom={16}
            >
                {tabs.map((tab, index) => (
                    <Tab
                        key={tab}
                        id={tab}
                        onSelect={() => { setSelectedTab(index)}}
                        isSelected={index === selectedTab}
                    >
                        {tab}
                    </Tab>
                ))}
            </Tablist>
            {tabsInline[selectedTab]}
        </Pane>
    )
}

export default SettingsPage