//Login 
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_CHECKED = 'AUTH_CHECKED'
export const AUTH_UNSUCCESS = 'AUTH_UNSUCCESS'
// export const AUTH_LOGOUT = 'AUTH_LOGOUT'


//influencers
export const RECEIVED_INFLUENCERS_LIST = 'RECEIVED_INFLUENCERS_LIST'
export const ADD_NEW_INFLUENCER = 'ADD_NEW_INFLUENCER'
export const EDIT_INFLUENCER = 'EDIT_INFLUENCER'
export const RECEIVED_SINGLE_INFLUENCER = 'RECEIVED_SINGLE_INFLUENCER'


//Projects
export const RECEIVED_PROJECTS_LIST = 'RECEIVED_PROJECTS_LIST'
export const ADD_NEW_PROJECT = 'ADD_NEW_PROJECT'
export const RECEIVED_SINGLE_PROJECT = 'RECEIVED_SINGLE_PROJECT'
export const DEACTIVATE_PROJECT = 'DEACTIVATE_PROJECT'

//Public
export const RECEIVED_POOL = 'RECEIVED_POOL'

//Tags
export const RECEIVED_TAGS = 'RECEIVED_TAGS'
export const ADD_TAG = 'ADD_TAG'
export const DELETED_TAG = 'DELETED_TAG'

//Platforms
export const RECEIVED_PLATFORMS = 'RECEIVED_PLATFORMS'
export const ADD_PLATFORM = 'ADD_PLATFORM'
export const DELETED_PLATFORM = 'DELETED_PLATFORM'

//Companies
export const RECEIVED_COMPANIES = 'RECEIVED_COMPANIES'
export const ADD_COMPANY = 'ADD_COMPANY'
export const DELETED_COMPANY = 'DELETED_COMPANY'