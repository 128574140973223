import React from 'react'

function redirectToRTA() {
    window.location.href = "https://rta-moscow.com/"
}

function RootPage() {
    return (
        <h1>{redirectToRTA()}</h1>

    )
}

export default RootPage