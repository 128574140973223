import React from 'react'
import {Animated} from 'react-animated-css'
import icon_dislike_no_shadow from '../img/icons/icon_dislike_no_shadow.svg'
import icon_question_no_shadow from '../img/icons/icon_question_no_shadow.svg'
import icon_like_no_shadow from '../img/icons/icon_like_no_shadow.svg'

function PublicRules(props) {
    return (
        <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={500} animationOutDuration={500}  isVisible={props.isVisible} className="w-100p">
            <div className="box__title pl-xl-65 pl-10 fw-900 c-white lh-120 pos-r z-1">Все просто</div>
            <div className="box-inner pos-r c-white px-xl-65">
                <div className="mb-15 fw-500 fz-14">Мы подобрали релевантных для вашего бренда инфлюенсеров.<br /> Осталось узнать, что вы о них думаете.</div>
                <div className="rules-wrapper fz-14 ta-xl-c mb-10">
                    <div className="row gx-10 jc-sb">
                        <div className="col-xl-auto mb-xl-20 mb-15">
                            <div className="row fxd-xl-c gx-15 ai-c">
                                <div className="col-xl-12 col-auto">
                                    <img src={icon_dislike_no_shadow} alt="dislike" />
                                </div>
                                <div className="col">
                                    <div className="fw-900 pt-xl-7 mb-2">Дизлайк</div>
                                    <div className="fw-500 lh-130">Этот инфлюенсер<br /> нам не подходит</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-auto mb-xl-20 mb-15">
                            <div className="row fxd-xl-c gx-15 ai-c">
                                <div className="col-xl-12 col-auto">
                                    <img src={icon_question_no_shadow} alt="dislike" />
                                </div>
                                <div className="col">
                                    <div className="fw-900 pt-xl-7 mb-2">Хм, не знаю, кто это</div>
                                    <div className="fw-500 lh-130">Вижу этого<br /> инфлюенсера впервые</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-auto mb-xl-20 mb-15">
                            <div className="row fxd-xl-c gx-15 ai-c">
                                <div className="col-xl-12 col-auto">
                                    <img src={icon_like_no_shadow} alt="like" />
                                </div>
                                <div className="col">
                                    <div className="fw-900 pt-xl-7 mb-2">Лайк</div>
                                    <div className="fw-500 lh-130">Этот инфлюенсер<br /> — точно про нас</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ta-c">
                    <button className="btn-red mr-xl-5" onClick={() => props.nextStage()}>ОК</button>
                </div>
            </div>
        </Animated>

    )
}

export default PublicRules