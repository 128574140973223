import React from 'react'
import { Animated } from 'react-animated-css'
import icon_dislike_no_shadow from '../img/icons/icon_dislike_no_shadow.svg'
import icon_question_no_shadow from '../img/icons/icon_question_no_shadow.svg'
import icon_like_no_shadow from '../img/icons/icon_like_no_shadow.svg'

function commaSeparateNumber(val) {
    while (/(\d+)(\d{3})/.test(val.toString())) {
        val = val.toString().replace(/(\d+)(\d{3})/, '$1' + ',' + '$2');
    }
    return val;
}


function InfluencerPollCard(props) {
    const splitedName = props.name.split(' ')

    const renderPlatforms = (data) => {
        const platforms = data.map((p, index) => (
            <a style={{
                wordWrap: "anywhere",
                cursor: "pointer",
                color: "#fff"
            }}
                className="fz-md-15 fz-12 fw-700 col-auto"
                href={p.link ? p.link : null}
                key={index}
                target="_blank"
            >
                {index < data.length - 1 ? `${p.value},` : p.value}
            </a>
        ))

        return platforms
    }

    return (
        <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={500} animationOutDuration={500} isVisible={props.isVisible} className="card-wrapper pos-r">
            <div className="card-wrapper-before"
                style={{ background: `linear-gradient(180deg, ${props.colors[0].hex} 0%, ${props.colors[1].hex} 100%)` }}
            ></div>

            <div className="card row fxd-c jc-c h-100p pos-r z-1">

                <div className="card-top bg-center col"
                    style={{ backgroundImage: `url(${props.downloadURL})` }}
                >
                    <div className="card-top__inner">
                        <div className="card-name c-white">{splitedName[0]}<br /> {splitedName[1]}</div>
                        <div className="card-info c-white">
                            <div className="row mx-0 ta-c">
                                <div className="col-4 card-info__col px-10 lh-110">
                                    <div className="row fxd-c h-100p ai-c">
                                        <div className="fz-md-12 fz-10 fw-500 mb-5">Площадка</div>
                                        {renderPlatforms(props.platform)}
                                    </div>
                                </div>
                                <div className="col-4 card-info__col px-10 lh-110">
                                    <div className="row fxd-c h-100p ai-c">
                                        <div className="fz-md-12 fz-10 fw-500 mb-5">Подписчики</div>
                                        <div className="fz-md-15 fz-12 fw-700 col-auto">{commaSeparateNumber(props.subscribers)}</div>
                                    </div>
                                </div>
                                <div className="col-4 card-info__col px-10 lh-110">
                                    <div className="row fxd-c h-100p ai-c">
                                        <div className="fz-md-12 fz-10 fw-500 mb-5">Категория</div>
                                        <div style={{
                                            wordWrap: "anywhere"
                                        }} className="fz-md-15 fz-12 fw-700 col-auto">{props.category.join(', ')}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card-bottom col-auto">
                    <div className="card_hashtags ta-c c-gray-light lh-110 pt-10">{props.hashTags.map(hasTag => `#${hasTag}`).join(' ')}</div>
                    <div className="row gx-5 ai-c jc-sb">
                        <div className="col-4 d-fx">
                            <button className="card-btn d-b"
                                onClick={() => props.handleClick(-1)}
                            >
                                <img src={icon_dislike_no_shadow} alt="" className="card-btn__icon img-fluid d-b mb-15 mb-sm-20 mt-sm-20 mt-15" />
                            </button>
                        </div>
                        <div className="col-4 jc-c d-fx">
                            <button className="card-btn d-b"
                                onClick={() => props.handleClick(0)}
                            >
                                <img src={icon_question_no_shadow} alt="" className="card-btn__icon img-fluid d-b mb-15 mb-sm-20 mt-sm-20 mt-15" />
                            </button>
                        </div>
                        <div className="col-4 jc-fe d-fx">
                            <button className="card-btn d-b"
                                onClick={() => props.handleClick(1)}
                            >
                                <img src={icon_like_no_shadow} alt="" className="card-btn__icon img-fluid d-b mb-15 mb-sm-20 mt-sm-20 mt-15" />
                            </button>
                        </div>
                    </div>
                    <div className="card-bottom-indent"></div>
                </div>

            </div>

        </Animated>
    )
}

export default InfluencerPollCard