import React, { useState, useEffect } from 'react'
import logo from '../../img/logo.svg'
import box_decor_left from '../../img/static/box_decor_left.svg'
import box_decor_right from '../../img/static/box_decor_right.svg'

import PublicForm from '../../components/PublicForm'
import PublicRules from '../../components/PublicRules'
import PublicPool from '../../components/PublicPool'
import PublicFinal from '../../components/PublicFinal'

import { useSelector, useDispatch } from 'react-redux'
import { getPool, savePool } from '../../store/actions/publicActions'

function PublicLayout(props) {


    const [name, setName] = useState("")
    const [surname, setSurname] = useState("")
    const [position, setPosition] = useState("")

    const [stage, setStage] = useState(0)
    const [visible, setVisible] = useState(0)


    const dispatch = useDispatch()
    const getPoolAction = (projectID) => dispatch(getPool(projectID))
    const newPollAction = (poll) => dispatch(savePool(poll))

    const poolSelector = useSelector(state => state.pool)


    useEffect(() => {
        getPoolAction(props.match.params.projectID)
    }, []);

    const setNextStage = () => {
        if (stage < 4) {
            setVisible(visible + 1)

            setTimeout(() => {
                setStage(stage + 1)
            }, 1000)
        }
    }

    const handlePollingResults = async (results) => {
        let returnPoll = {
            projectID: props.match.params.projectID,
            companyID: poolSelector.companyID,
            name: name,
            surname: surname,
            position: position,
            votes: results,
            influencers: results.map(vote => vote.id)
        }
        if (name === 'test' && surname === 'test' && position === 'test') return
        await newPollAction(returnPoll)
    }

    if (poolSelector.status === 0) {
        window.location.href = 'https://rta-moscow.com/' 
    }
    return (
        <div className="wrapper">
            <h1 className="logo" style={{
                color: "#fff"
            }}>{poolSelector.company ? poolSelector.company : null}</h1>
            <div className="box box_card pos-r mx-a fxd-c h-100p">
                <img src={box_decor_left} alt="" className="box_before" />
                <img src={box_decor_right} alt="" className="box_after" />


                {stage === 0
                    ? <PublicForm
                        name={name}
                        setName={setName}
                        surname={surname}
                        setSurname={setSurname}
                        position={position}
                        setPosition={setPosition}
                        nextStage={setNextStage}
                        isVisible={visible === 0}
                    />
                    : null
                }

                {stage === 1
                    ? <PublicRules
                        nextStage={setNextStage}
                        isVisible={visible === 1}
                    />
                    : null
                }

                {stage === 2
                    ? <PublicPool
                        influencers={poolSelector.influencers}
                        colors={poolSelector.colors}
                        nextStage={setNextStage}
                        handlePollingResults={(results) => handlePollingResults(results)}
                        isVisible={visible === 2}
                    />
                    : null
                }

                {stage === 3
                    ? <PublicFinal
                        nextStage={setNextStage}
                        isVisible={visible === 3}
                    />
                    : null
                }

            </div>



        </div>
    )
}

export default PublicLayout