import firebase from '../../firebase/config'

import { RECEIVED_COMPANIES, ADD_COMPANY, DELETED_COMPANY } from './actionTypes'

export function getCompanies() {
    return async dispatch => {
        const firebaseAnswer = await firebase.getCompanies()

        if (firebaseAnswer) dispatch({
            type: RECEIVED_COMPANIES,
            payload: firebaseAnswer
        })
    }
}

export function addCompany(company){
    return async dispatch => {
        const firebaseAnswer = await firebase.addCompany(company)

        if(firebaseAnswer) dispatch({
            type: ADD_COMPANY,
            payload: firebaseAnswer
        })
    }
}

export function companyDelete(company){
    return async dispatch => {
        const firebaseAnswer = await firebase.deleteCompany(company)

        if(firebaseAnswer) dispatch({
            type: DELETED_COMPANY,
            payload: company
        })
    }
}