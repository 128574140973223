import firebase from '../../firebase/config'


import { ADD_NEW_PROJECT, RECEIVED_PROJECTS_LIST, DEACTIVATE_PROJECT } from './actionTypes'

export function newProject(project) {
    return async dispatch => {
        const firebaseAnswer = await firebase.createProject(project)
        
        if (firebaseAnswer) dispatch({
            type: ADD_NEW_PROJECT,
            payload: firebaseAnswer
        })
    }
}

export function getProjectsList(){
    return async dispatch => {
        const firebaseAnswer = await firebase.getProjects()

        if(firebaseAnswer) dispatch({
            type: RECEIVED_PROJECTS_LIST,
            payload: firebaseAnswer
        })
    }
}

export function deactivateProject(project) {
    return async dispatch => {
        const firebaseAnswer = await firebase.deactivateProject(project)

        if (firebaseAnswer) dispatch({
            type: DEACTIVATE_PROJECT,
            payload: firebaseAnswer
        })
    }
}