import React from 'react'
import rounds from '../img/icons/rounds.svg'
import {Animated} from 'react-animated-css'


function PublicForm(props) {

    const handleForm = ()=>{
        if (props.name.trim().length === 0) return
        if (props.surname.trim().length === 0) return
        if (props.position.trim().length === 0) return
        props.nextStage()
    }

    return (
        <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={500} animationOutDuration={500}  isVisible={props.isVisible} className="w-100p">
            <div className="box__title fw-900 c-white lh-120 pos-r ta-lg-l ta-c z-1">Представьтесь</div>
            <div className="box-inner pos-r">
                <div className="row ai-c gx-xl-50 mb-15">
                    <div className="col">
                        <input type="text" className="input" placeholder="Имя" value={props.name} onChange={e=>props.setName(e.target.value)}/>
                    </div>
                    <div className="d-lg-b d-n col-auto">
                        <img src={rounds} alt="" className="mr-xl-15" />
                    </div>
                </div>
                <div className="row ai-c gx-xl-50 mb-15">
                    <div className="col">
                        <input type="text" className="input" placeholder="Фамилия" value={props.surname} onChange={e=>props.setSurname(e.target.value)}/>
                    </div>
                    <div className="d-lg-b d-n col-auto">
                        <img src={rounds} alt="" className="mr-xl-15" />
                    </div>
                </div>
                <div className="row ai-c gx-xl-50 mb-20">
                    <div className="col">
                        <input type="text" className="input" placeholder="Должность" value={props.position} onChange={e=>props.setPosition(e.target.value)}/>
                    </div>
                    <div className="d-lg-b d-n col-auto">
                        <img src={rounds} alt="" className="mr-xl-15" />
                    </div>
                </div>
                <div className="ta-lg-r ta-c">
                    <button className="btn-red mr-xl-5" onClick={handleForm}>ОК</button>
                </div>
            </div>
        </Animated>
    )
}
export default PublicForm