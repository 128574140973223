import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import firebase from '../firebase/config'
import { Pane, Spinner, Heading, Text, Table } from 'evergreen-ui'
import { useHistory } from 'react-router-dom'
import { filter } from 'fuzzaldrin-plus'
import { ExportCSV } from '../components/ExcelExportButton'

function formatDate(date) {

    var dd = date.getDate();
    if (dd < 10) dd = '0' + dd;

    var mm = date.getMonth() + 1;
    if (mm < 10) mm = '0' + mm;

    var yy = date.getFullYear() % 100;
    if (yy < 10) yy = '0' + yy;

    return dd + '-' + mm + '-' + yy;
}

function getWorldFromScore(score) {
    switch (score) {
        case 1:
            return "Лайк"

        case 0:
            return "Не знаю"

        case -1:
            return "Не нравится"

        default:
            return "Ошибка"
    }
}

function CompanyPage(props) {

    const [polls, setPolls] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [searchQuery, setSearchQuery] = useState("")

    const projectsSelector = useSelector(state => state.projects)
    const companiesSelector = useSelector(state => state.companies)
    const influencersSelector = useSelector(state => state.influencers)

    const history = useHistory()

    useEffect(() => {
        getPollsForCompany(props.match.params.companyID, props.match.params.influencerID)
        console.log(polls)
    }, [])

    const getPollsForCompany = async (companyID, influencerID) => {
        await firebase.getPollsForCompany(companyID, influencerID).then(polls => {
            setIsLoading(false)
            setPolls(polls)
        })
    }

    const getCompanyById = (companyId) => {
        return companiesSelector.companies.find(company => company.id === companyId)
    }

    const getProjectById = (projectID) => {
        return projectsSelector.projects.find(project => project.id === projectID)
    }

    const getInfluencerById = (influencerID) => {
        return influencersSelector.influencers.find(influencer => influencer.id === influencerID)
    }

    const filterByName = projects => {
        if (searchQuery.length === 0) return projects

        return projects.filter(project => {
            const result = filter([project.name], searchQuery)
            return result.length === 1
        })
    }


    const prepareDataToExport = (polls) => {
        const returnData = []
        polls.map(poll => {
            const returnPoll = {
                'Имя': poll.name,
                'Фамилия': poll.surname,
                'Должность': poll.position,
                'Компания': getCompanyById(poll.companyID).label,
                'Проект': getProjectById(poll.projectID).name
            }
            poll.votes.map(vote => {
                let withInfluencer = false
                if (props.match.params.influencerID) withInfluencer = true
                if (props.match.params.influencerID === vote.id || !withInfluencer) {
                    returnPoll[vote.name] = getWorldFromScore(vote.score)
                }
            })
            returnData.push(returnPoll)

        })
        return returnData
    }


    const getUniqueProjects = (polls) => {
        const uniqueProjectsIDs = [...new Set(polls.map(poll => {
            return poll.projectID
        }))]
        let projectsWithState = {}
        uniqueProjectsIDs.map(id => projectsWithState[id] = { good: 0, bad: 0, neutral: 0 })

        polls.map(poll => poll.votes.map(vote => {
            let withInfluencer = false
            if (props.match.params.influencerID) withInfluencer = true
            if (props.match.params.influencerID === vote.id || !withInfluencer) {
                switch (vote.score) {
                    case 0:
                        projectsWithState[poll.projectID].neutral = projectsWithState[poll.projectID].neutral + 1
                        break
                    case 1:
                        projectsWithState[poll.projectID].good = projectsWithState[poll.projectID].good + 1
                        break
                    case -1:
                        projectsWithState[poll.projectID].bad = projectsWithState[poll.projectID].bad + 1
                        break
                    default:
                        break
                }
            }

        }))
        let returnArray = []
        Object.keys(projectsWithState).map(projectId => {
            const findedProject = projectsSelector.projects.find(project => project.id === projectId)
            returnArray.push({ ...findedProject, ...projectsWithState[projectId] })
        })

        return returnArray
    }

    const renderTableRow = (project) => (
        <Table.Row key={project.id} isSelectable onSelect={() => history.push(`/admin/projects/${project.id}`)}>
            <Table.TextCell>{project.name}</Table.TextCell>
            <Table.TextCell>{project.good}</Table.TextCell>
            <Table.TextCell>{project.neutral}</Table.TextCell>
            <Table.TextCell>{project.bad}</Table.TextCell>
        </Table.Row>
    )


    if (isLoading) return (
        <Pane display="flex" alignItems="center" justifyContent="center" height={400}>
            <Spinner />
        </Pane>
    )

    const uniqueProjects = getUniqueProjects(polls)
    const filtredProjects = filterByName(uniqueProjects)
    return (
        <React.Fragment>
            <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
            }}>
                <Heading size={900}>{getCompanyById(props.match.params.companyID).label}</Heading>
                <ExportCSV csvData={prepareDataToExport(polls)} fileName={`${getCompanyById(props.match.params.companyID).label}-${formatDate(new Date())}`} />
            </div>

            {props.match.params.influencerID
                ? <Heading size={700} marginTop={10}>{getInfluencerById(props.match.params.influencerID).name}</Heading>
                : null
            }
            <Text size={400}>Данная станица предназначена для просмотра статистики комании. <br /> Для того, чтобы здесь отобразился проект, необходимо, чтобы его прошли хотя бы один раз.</Text>
            {polls
                ? <React.Fragment>
                    <Heading size={600} marginTop={30}>Проекты</Heading>
                    <Table border marginTop={10}>
                        <Table.Head>
                            <Table.SearchHeaderCell
                                onChange={value => setSearchQuery(value)}
                                placeholder='Искать по названию...'
                            />
                            <Table.TextCell>Лайк</Table.TextCell>
                            <Table.TextCell>Не знаю</Table.TextCell>
                            <Table.TextCell>Не лайк</Table.TextCell>
                        </Table.Head>
                        <Table.Body>
                            {filtredProjects.map(project => renderTableRow(project))}

                        </Table.Body>
                    </Table>
                </React.Fragment>
                : null}
        </React.Fragment>
    )
}

export default CompanyPage