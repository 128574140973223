import { RECEIVED_PROJECTS_LIST, ADD_NEW_PROJECT, DEACTIVATE_PROJECT } from '../actions/actionTypes'


const initialState = {
    projects: [],
    isLoading: true,
    hasError: false
}

export default function projectsReducer(state = initialState, action) {
    switch (action.type) {
        case RECEIVED_PROJECTS_LIST:
            return { ...state, projects: action.payload, isLoading: false }
        case ADD_NEW_PROJECT:
            {
                let projectsList = state.projects
                projectsList.push(action.payload)
                return { ...state, projects: projectsList }
            }
        case DEACTIVATE_PROJECT:
            return {...state, projects: [...state.projects.filter(project => project.id !== action.payload.id), action.payload]}
        default:
            return state
    }
}