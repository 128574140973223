import React from 'react'
import { SideSheet, Pane, Avatar, Heading, Text, Button, EditIcon, PanelStatsIcon } from 'evergreen-ui'
import { useHistory } from 'react-router-dom'

function getStingFromTags(tags) {
    return tags.join(', ')
}

function InfluencerInfoSideSheet(props) {

    const history = useHistory()

    const renderPlatforms = (data) => {
        const platforms = data.map((p, index) => (
            <a style={{
                wordWrap: "anywhere",
                cursor: "pointer",
                color: "#234361"
            }}
                target="_blank"
                href={p.link ? p.link : null}
                key={index}
            >
                {index < data.length - 1 ? `${p.value}, ` : p.value}
            </a>
        ))

        return platforms
    }

    return (
        <SideSheet
            isShown={props.isSingleInfShown}
            onCloseComplete={props.onCloseComplete}
            containerProps={{
                display: 'flex',
                flex: '1',
                flexDirection: 'column',
            }}
        >

            <Pane background="tint1" flex="1" padding={32}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "start"
                    }}
                >
                    <Avatar
                        src={props.influencer.downloadURL}
                        size={200}
                        marginRight={30}
                    />
                    <div>
                        <Heading size={800}>{props.influencer.name}</Heading>

                        <Heading size={600} marginTop={10}>Подписчики</Heading>
                        <Text size={500}>{props.influencer.subscribers}</Text>

                        <Heading size={600} marginTop={10}>Платформы</Heading>
                        {props.influencer.platform ? renderPlatforms(props.influencer.platform) : null}

                        <Heading size={600} marginTop={10}>Тэги</Heading>
                        <Text size={500}>{props.influencer.tags ? getStingFromTags(props.influencer.tags) : null}</Text>

                        <Heading size={600} marginTop={10}>Комментарий</Heading>
                        <Text size={500}>{props.influencer.comment || 'Пусто'}</Text>
                    </div>

                </div>

                <Button position="absolute" onClick={() => history.push(`/admin/influencers/edit/${props.influencer.id}`)} height={40} bottom={32} right={32} iconBefore={EditIcon} appearance="primary" intent="success">Редактировать</Button>
                <Button position="absolute" onClick={() => history.push(`/admin/influencers/stat/${props.influencer.id}`)} height={40} bottom={32} left={32} iconBefore={PanelStatsIcon} appearance="primary">Статистика</Button>



            </Pane>



        </SideSheet>
    )
}

export default InfluencerInfoSideSheet