import { RECEIVED_TAGS, ADD_TAG, DELETED_TAG} from '../actions/actionTypes'
const initialState = {
    tags: [],
    isLoading: true,
    hasError: false
}

export default function tagReducer(state = initialState, action) {
    switch (action.type) {
        case RECEIVED_TAGS:
            return { ...state, tags: [...action.payload], isLoading: false }
        case ADD_TAG:
            return { ...state, tags: [...state.tags, action.payload] }
        case DELETED_TAG:
            return { ...state, tags: [...state.tags.filter(tag => tag.id !== action.payload.id)] }
        default:
            return state
    }
}