import firebase from '../../firebase/config'

import { RECEIVED_PLATFORMS, ADD_PLATFORM, DELETED_PLATFORM } from './actionTypes'

export function getPlatforms() {
    return async dispatch => {
        const firebaseAnswer = await firebase.getPlatforms()

        if (firebaseAnswer) dispatch({
            type: RECEIVED_PLATFORMS,
            payload: firebaseAnswer
        })
    }
}

export function addPlatform(platform){
    return async dispatch => {
        const firebaseAnswer = await firebase.addPlatform(platform)

        if(firebaseAnswer) dispatch({
            type: ADD_PLATFORM,
            payload: firebaseAnswer
        })
    }
}

export function platformDelete(platform){
    return async dispatch => {
        const firebaseAnswer = await firebase.deletePlatform(platform)

        if(firebaseAnswer) dispatch({
            type: DELETED_PLATFORM,
            payload: platform
        })
    }
}