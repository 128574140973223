import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Table, SelectMenu, TextDropdownButton, Avatar, Text, Heading } from 'evergreen-ui'
import { filter } from 'fuzzaldrin-plus'


function getStrFromScore(score) {
    switch (score) {
        case -1:
            return 'Не лайк'
        case 0:
            return 'Не знаю'
        case 1:
            return 'Лайк'
        default:
            return 'Ошибка'
    }
}

function PollResults(props) {

    const [votes, setVotes] = useState([])
    const [scoreFilter, setScoreFilter] = useState([])


    const scoreOptions = [
        { label: 'Не лайк', value: 'Не лайк' },
        { label: 'Не знаю', value: 'Не знаю' },
        { label: 'Лайк', value: 'Лайк' }
    ]


    useEffect(() => {
        if (props.polls) {
            setVotes(getVotes(props.polls))
        }

    }, [])

    const getInfluencersArray = (votes) => {
        console.log(votes)
        const uniqueInfluencersNames =  [...new Set(votes.map(vote => {
            return vote.influencerName
        }))]
        
        return uniqueInfluencersNames.map(name => {return {value: name, label: name}})
    }

    const getVotes = (polls) => {
        const returnData = []
        polls.map(poll => {
            poll.votes.map(vote => {
                const returnPoll = {
                    name: poll.name,
                    surname: poll.surname,
                    position: poll.position,
                    influencerName: vote.name,
                    influencerScore: getStrFromScore(vote.score)
                }
                returnData.push(returnPoll)
            })
            

        })
        return returnData
    }

    const filterByName = votes => {
        if (props.searchQuery.length === 0) return votes

        return votes.filter(vote => {
            const fullName = `${vote.surname} ${vote.name} ${vote.position}`
            const result = filter([fullName], props.searchQuery)
            return result.length === 1
        })
    }

    const sortByInfluencer = votes => {
        if (props.influencerFilter.length === 0) return votes

        return votes.filter(vote => {
            return props.influencerFilter.some((filterInfluencerName) => {
                return !!~vote.influencerName.indexOf(filterInfluencerName)
            })
        })
    }

    const sortByScore = votes => {
        if (scoreFilter.length === 0) return votes

        return votes.filter(vote => {
            return scoreFilter.some((filterInfluencerScore) => {
                return !!~vote.influencerScore.indexOf(filterInfluencerScore)
            })
        })
    }

    const renderInfluencersCell = () => {
        return (
            <Table.HeaderCell>
                <SelectMenu
                    isMultiSelect
                    title="Выбор инфлюенсера"
                    filterPlaceholder={"Введите инфлюенсера..."}
                    options={getInfluencersArray(votes)}
                    height={305}
                    selected={props.influencerFilter}
                    onSelect={item => {
                        const selectedItems = [...props.influencerFilter, item.value]
                        props.setInfluencerFilter(selectedItems)
                    }}
                    onDeselect={item => {
                        const deselectedItemIndex = props.influencerFilter.indexOf(item.value)
                        const selectedItems = props.influencerFilter.filter(
                            (_item, i) => i !== deselectedItemIndex
                        )
                        props.setInfluencerFilter(selectedItems)
                    }}
                >
                    <TextDropdownButton>Инфлюенсер</TextDropdownButton>
                </SelectMenu>
            </Table.HeaderCell>
        )
    }

    const renderRow = (vote) => {
        return (
            <Table.Row key={vote.name + vote.surname + vote.position + vote.influencerName + vote.influencerScore} isSelectable>
                <Table.Cell>
                    <Avatar name={vote.name} />
                    <Text marginLeft={8} size={300} fontWeight={500}>
                        {`${vote.surname} ${vote.name} ${vote.position}`}
                    </Text>
                </Table.Cell>

                <Table.TextCell>{vote.influencerName}</Table.TextCell>
                <Table.TextCell>{vote.influencerScore}</Table.TextCell>
            </Table.Row>
        )
    }

    const renderScoreCell = () => {
        return (
            <Table.HeaderCell>
                <SelectMenu
                    isMultiSelect
                    hasTitle={false}
                    hasFilter={false}
                    options={scoreOptions}
                    height={100}
                    selected={scoreFilter}
                    onSelect={item => {
                        const selectedItems = [...scoreFilter, item.value]
                        setScoreFilter(selectedItems)
                    }}
                    onDeselect={item => {
                        const deselectedItemIndex = scoreFilter.indexOf(item.value)
                        const selectedItems = scoreFilter.filter(
                            (_item, i) => i !== deselectedItemIndex
                        )
                        setScoreFilter(selectedItems)
                    }}
                >
                    <TextDropdownButton>Оценка</TextDropdownButton>
                </SelectMenu>
            </Table.HeaderCell>
        )
    }


    const filtredVotes = filterByName(sortByScore(sortByInfluencer(votes)))
    return (
        <React.Fragment>
            <Heading size={700} marginTop={40} marginBottom={10}>Результаты</Heading>
            <Table marginTop={30}>
                <Table.Head>
                    <Table.SearchHeaderCell
                        placeholder='Поиск по имени...'
                        onChange={value => props.setSearchQuery(value.trim())}
                    />
                    {renderInfluencersCell()}
                    {renderScoreCell()}
                </Table.Head>

                <Table.Body>
                    {filtredVotes.map(vote => renderRow(vote))}
                </Table.Body>
            </Table>
        </React.Fragment>

    )
}

export default PollResults