import firebase from '../../firebase/config'
import { toaster } from 'evergreen-ui'
import { AUTH_SUCCESS, AUTH_CHECKED, AUTH_UNSUCCESS } from './actionTypes'

export function loginUser(email, password) {
    return async dispatch => {
        const user = await firebase.login(email, password).catch(err => { toaster.danger('Ошибка входа'); console.log(err) })

        // const expirationDate = new Date((new Date()).getTime() + user.expiresIn * 1000)

        // console.log(user.idToken)

        // // localStorage.setItem('token', user.idToken)
        // // localStorage.setItem('userId', user.localId)
        // // localStorage.setItem('expirationDate', expirationDate)
        if (user) {
            dispatch(authSuccess(user))
        }else {
            dispatch(authUnSuccess())
        }

        return user
    }
}

export function checkLogin() {
    return async dispatch => {

        const user = await firebase.checkAuth()
        dispatch({
            type: AUTH_CHECKED,
            payload: user
        })
    }
}

// export function autoLogout(time) {
//     return dispatch => {
//         setTimeout(() => {
//             dispatch(logout())
//         }, time * 1000)
//     }
// }

export function authSuccess(user) {
    return {
        type: AUTH_SUCCESS,
        payload: user
    }
}

export function authUnSuccess() {
    return {
        type: AUTH_UNSUCCESS
    }
}

// export function logout() {

//     localStorage.removeItem('token')
//     localStorage.removeItem('userId')
//     localStorage.removeItem('expirationDate')

//     return {
//         type: AUTH_LOGOUT
//     }
// }



