import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

export const PrivateRoute = ({ component: Component, ...rest }) => {

    const authSelector = useSelector(state => state.auth)

    return (
        !authSelector.isLoading
            ? <Route {...rest} render={(props) => (
                authSelector.user
                    ? <Component {...props} />
                    : <Redirect to='/admin/login' />
            )} />
            : null

    )
}

