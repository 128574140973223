import { RECEIVED_COMPANIES, ADD_COMPANY, DELETED_COMPANY } from '../actions/actionTypes'
const initialState = {
    companies: [],
    isLoading: true,
    hasError: false
}

export default function companyReducer(state = initialState, action) {
    switch (action.type) {
        case RECEIVED_COMPANIES:
            return { ...state, companies: [...action.payload], isLoading: false }
        case ADD_COMPANY:
            return { ...state, companies: [...state.companies, action.payload] }
        case DELETED_COMPANY:
            return { ...state, companies: [...state.companies.filter(company => company.id !== action.payload.id)] }
        default:
            return state
    }
}