import React from 'react'


function commaSeparateNumber(val) {
    while (/(\d+)(\d{3})/.test(val.toString())) {
        val = val.toString().replace(/(\d+)(\d{3})/, '$1' + ',' + '$2');
    }
    return val;
}

function PreviewInfluencer(props) {
    const splitedName = props.name.split(' ')

    const renderPlatforms = (data) => {
        const sliced = data.slice(0, 3)
        const platforms = sliced.map((p, index) => (
            <a style={{
                wordWrap: "anywhere",
                cursor: "pointer",
                color: "#fff"
            }}
                className="fz-md-15 fz-12 fw-700 col-auto"
                href={p.link ? p.link : null}
                key={index}
                target="_blank"
            >
                {index < sliced.length - 1 ? `${p.value},` : p.value}
            </a>
        ))

        return platforms
    }
    return (
        <div style={{
            height: "540px",
            width: "487px"
        }}>
            <div className="card-wrapper pos-r">
                <div className="card-wrapper-before"
                    style={{ background: `linear-gradient(180deg, ${props.colors[0].hex} 0%, ${props.colors[1].hex} 100%)` }}
                ></div>

                <div className="card row fxd-c jc-c h-100p pos-r z-1">

                    <div className="card-top bg-center col"
                        style={{ backgroundImage: `url(${props.downloadURL})` }}
                    >
                        <div className="card-top__inner">
                            <div className="card-name c-white">{splitedName[0]}<br /> {splitedName[1]}</div>
                            <div className="card-info c-white">
                                <div className="row mx-0 ta-c">
                                    <div className="col-4 card-info__col px-10 lh-110">
                                        <div className="row fxd-c h-100p ai-c">
                                            <div className="fz-md-12 fz-10 fw-500  mb-5">Площадка</div>
                                            {
                                                renderPlatforms(props.platform)
                                            }
                                        </div>
                                    </div>
                                    <div className="col-4 card-info__col px-10 lh-110">
                                        <div className="row fxd-c h-100p ai-c">
                                            <div className="fz-md-12 fz-10 fw-500  mb-5">Подписчики</div>
                                            <div className="fz-md-15 fz-12 fw-700 col-auto">{commaSeparateNumber(props.subscribers)}</div>
                                        </div>
                                    </div>
                                    <div className="col-4 card-info__col px-10 lh-110">
                                        <div className="row fxd-c h-100p ai-c">
                                            <div className="fz-md-12 fz-10 fw-500  mb-5">Категория</div>
                                            <div style={{
                                                wordWrap: "anywhere"
                                            }} className="fz-md-15 fz-12 fw-700 col-auto">{props.category.slice(0, 3).join(', ')}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



    )
}

export default PreviewInfluencer