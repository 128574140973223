import React from 'react'
import { Link } from 'react-router-dom'
import { Heading, Button, PlusIcon } from 'evergreen-ui'

import ProjectsList from '../components/ProjectsList'

function ProjectsListPage() {
    return (
        <React.Fragment>
            <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "10px",
                marginTop: "50px"
            }}>
                <Heading size={900} marginBottom={8}>Проекты</Heading>
                <Link to="/admin/projects/new"><Button iconBefore={PlusIcon} height={40} appearance="primary" intent="success">Добавить проект</Button></Link>
            </div>
            <ProjectsList />
        </React.Fragment>
    )
}

export default ProjectsListPage