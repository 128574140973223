import React, { useState } from 'react'
import InfluencerPoolCard from './InfluencerPollCard'


function PublicPool(props) {

    const [poolingIndex, setPoolingIndex] = useState(0)
    const [isVisible, setIsVisible] = useState(true)
    const [resultsArray, setResultsArray] = useState([])

    const handleClick = (type) => {
        setIsVisible(false)
        let returnInfluencer = {
            id: props.influencers[poolingIndex].id,
            name: props.influencers[poolingIndex].name,
            score: type
        }
        let returnArray = resultsArray

        returnArray.push(returnInfluencer)
        setResultsArray(resultsArray)

        setTimeout(() => {
            console.log(poolingIndex, props.influencers.length)
            if (poolingIndex < props.influencers.length - 1) {
                setPoolingIndex(poolingIndex + 1)
                setIsVisible(true)
            } else {
                props.handlePollingResults(resultsArray)
                props.nextStage()
            }
        }, 500)

    }

    const renderInfluencer = (influencer, colors) => {
        if (!influencer) return null
        return (
            <InfluencerPoolCard
                name={influencer.name}
                platform={influencer.platform}
                subscribers={influencer.subscribers}
                category={influencer.category}
                hashTags={influencer.hashTags}
                downloadURL={influencer.downloadURL}
                colors={colors}
                handleClick={(type) => handleClick(type)}
                isVisible={isVisible}
            />
        )
    }
    return (
        <React.Fragment>
            {props.influencers ? renderInfluencer(props.influencers[poolingIndex], props.colors) : null}
        </React.Fragment>

    )
}

export default PublicPool