import React from 'react'
import { NavLink } from 'react-router-dom'
import { Button } from 'evergreen-ui'

function Menu() {
    return (

        <div style={{
            padding: "15px 0 25px 0",
            display: "flex",
            justifyContent: "flex-end"
        }}>

            <NavLink className="nav-link" to="/admin/influencers"><Button marginLeft={16} height={40} appearance="minimal" intent="none">Инфлюенсеры</Button></NavLink>
            <NavLink className="nav-link" to="/admin/projects"><Button marginLeft={16} height={40} appearance="minimal" intent="none">Проекты</Button></NavLink>
            <NavLink className="nav-link" to="/admin/companies"><Button marginLeft={16} height={40} appearance="minimal" intent="none">Компании</Button></NavLink>
            <NavLink className="nav-link" to="/admin/settings"><Button marginLeft={16} height={40} appearance="minimal" intent="none">Настройки</Button></NavLink>

        </div >


    )
}

export default Menu